import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { aggregateValue } from 'elements/ReactTableReUseAbleComponent/MaterialUITable';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import 'react-tippy/dist/tippy.css';
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { LinearProgress } from '@material-ui/core';

//align table on dashboard and income page 
//top 20 and bottom 20 account 
// top 5 and bottom 5 EDC
const useStyles = makeStyles(styles);

// create table on dashboard and income page
// Top 20 and bottom 20 Accounts for Gross Margin Under Contract
// Top 5 and bottom 5 EDCs for Gross Margin Under Contract
// Top 5 and bottom 5 EDCs for Unit Margin Under Contract
class SimpleTable extends Component {
    ISOUnderContractTable = ({ table_data, measure, header, currency }) => {
        //console.log(table_data)
        //console.log(currency)
        const classes = useStyles({
            table: {
                minWidth: 100,
            },
        });
        const StyledTableCell = withStyles(theme => ({
            head: {
                backgroundColor: 'rgb(21, 96, 153)',
                color: theme.palette.common.white,
            },
            body: {
                fontSize: 14,
            },
        }))(TableCell);

        const StyledTableRow = withStyles(theme => ({
            root: {
                '&:nth-of-type(odd)': {
                    backgroundColor: theme.palette.background.default,
                },
            },
        }))(TableRow);
        /*
        1 - dollar
        2 - volume 
        3 - unit margin
        */
        return (
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{header}</StyledTableCell>
                            <StyledTableCell align="right">{measure}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currency === 1 ? table_data.map(row => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row" background={row.color}>
                                    <i style={{ color: this.props.useIsoColor ? table_data.isoColor : row.color }} className="fa fa-circle icon"></i>&nbsp;
                                    {row.name}
                                </StyledTableCell>
                                <StyledTableCell align="right" >
                                    {aggregateValue(parseFloat(row.value), true)}
                                </StyledTableCell>
                            </StyledTableRow>
                        )) : currency === 2 ? table_data.map(row => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row" background={row.color}>
                                    <i style={{ color: this.props.useIsoColor ? table_data.isoColor : row.color }} className="fa fa-circle icon"></i>&nbsp;
                                    {row.name}
                                </StyledTableCell>
                                <StyledTableCell align="right" >
                                    {aggregateValue(parseFloat(row.value), false)}
                                </StyledTableCell>
                            </StyledTableRow>
                        )) : currency === 3 ? table_data.map(row => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row" background={row.color}>
                                    <i style={{ color: this.props.useIsoColor ? table_data.isoColor : row.color }} className="fa fa-circle icon"></i>&nbsp;
                                    {row.name}
                                </StyledTableCell>
                                <StyledTableCell align="right" >
                                    {row.value.toFixed(2)} $/MWh
                                </StyledTableCell>
                            </StyledTableRow>
                        )) : <LinearProgress />}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
    render() {
        return (
            <>
                <this.ISOUnderContractTable
                    table_data={this.props.data ? this.props.data : []}
                    measure={this.props.measure}
                    header={this.props.header}
                    currency={this.props.currency}
                />
            </>
        )
    }
}
SimpleTable.propTypes = {
    data: PropTypes.array,
    header: PropTypes.string,
    measure: PropTypes.string,
    currency: PropTypes.bool,
    useIsoColor: PropTypes.bool
}
export default SimpleTable;
