import React, { Component } from 'react';
import {
    XAxis, YAxis, Tooltip, CartesianGrid, Brush, ResponsiveContainer, Bar, Cell, BarChart
} from 'recharts';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ListMonth, capitalize } from '../../utils/helper';
import { CustomizedAxisTick, CustomToolTipDashboardDollar, CustomYAxisMWh, CustomYAxisDollar, CustomToolTipDashboardVolume } from '../../utils/rechartHelper'
import {
    ColorButton
} from '../../utils/materialUI_Reuseable_Comp'

class ReusableGraphISOTotal extends Component {
    constructor() {
        super();
        this.state = {
            testData: [],
            isoTotalData_KeyValuePair: [],
            isLoadingGraph: 0,
            startIndex: 0,
            endIndex: 10,
            interval: 0,
            totalVolumeISO_Hide: true,
            selectedISO_Name: null,
            selectedISO_Color: null,
            selectedISO_Data_KeyPair: [],
        }
    }
    handleClickTotalISO_Hide = (event) => {
        var Volume_Data_Select_ISO = this.props.data_to_drawGraph_Monthly
        const { yearList } = this.props;
        //console.log(Volume_Data_Select_ISO)
        var selectedISO_Data_KeyPair = [];
        // console.log(event.name)
        //console.log(event.color)
        //console.log(event)
        Volume_Data_Select_ISO.map(a => {
            if (a.iso === event.iso) {
                yearList.map(b => { // map year to extract data 
                    ListMonth.map(c => {//map month to extract data
                        let obj_volume = {};
                        obj_volume.date = capitalize(c.month) + ", " + b.year;

                        if (a.values[b.year] !== undefined) {
                            if (a.values[b.year].monthly[c.month] !== undefined) {
                                obj_volume["value"] = a.values[b.year].monthly[c.month]
                            } else {
                                obj_volume["value"] = 0
                            }
                        } else {
                            obj_volume["value"] = 0
                        }

                        selectedISO_Data_KeyPair.push(obj_volume)
                        return null
                    })
                    return null
                })
            }
            return null
        })
        //console.log("iso level click")
        //selectedISO_Data_KeyPair.sort((a, b) => b.value - a.value)
        //console.log(selectedISO_Data_KeyPair)
        this.setState({
            totalVolumeISO_Hide: false,
            selectedISO_Name: event.iso,
            selectedISO_Color: event.color,
            selectedISO_Data_KeyPair
        })
    }
    handleClickTotalISO_Back = () => {
        //console.log("back - total iso level click")
        this.setState({
            totalVolumeISO_Hide: true,
        })
    }
    ReusableGraphISOTotalUI = () => {

        var dataGraph = this.props.data_to_drawGraph_total
        var dollarGraphCondition = this.props.View_Dollar_Graph
        return (
            <>
                {this.state.totalVolumeISO_Hide === true ?
                    <>{dataGraph[0] !== undefined ?
                        <>*Please Select ISO to View Its Details by Monthly<br></br>
                            <ResponsiveContainer height={310}>
                                <BarChart data={dataGraph} margin={{ top: 0, right: 40, left: 60, bottom: 0 }} padding={{ top: 0, right: 40, left: 40, bottom: 0 }}>
                                    <XAxis dataKey='iso' tick={<CustomizedAxisTick />} />
                                    <YAxis tick={dollarGraphCondition === true ? <CustomYAxisDollar /> : <CustomYAxisMWh />} />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip content={dollarGraphCondition === true ? <CustomToolTipDashboardDollar /> : <CustomToolTipDashboardVolume />} />
                                    <Bar barSize={50} dataKey='value' onClick={this.handleClickTotalISO_Hide}>
                                        {
                                            dataGraph.map((entry, index) => {
                                                return <Cell key={`cell-${index}`} fill={entry.color} /*dataKey={entry.value}*/ />
                                            })
                                        }
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </> : <LinearProgress />}
                    </>
                    : this.state.totalVolumeISO_Hide === false ?
                        <>
                            <ColorButton variant="contained"
                                onClick={this.handleClickTotalISO_Back}>
                                Back up to ISO Level </ColorButton>
                            <ResponsiveContainer height={400}>
                                <BarChart data={this.state.selectedISO_Data_KeyPair}
                                    margin={{ top: 20, right: 5, left: 60, bottom: 20 }}>
                                    <XAxis height={60} dataKey='date' tick={<CustomizedAxisTick />} />
                                    <YAxis tick={dollarGraphCondition === true ? <CustomYAxisDollar /> : <CustomYAxisMWh />} />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip content={dollarGraphCondition === true ? <CustomToolTipDashboardDollar /> : <CustomToolTipDashboardVolume />} />
                                    <Bar barSize={5} dataKey='value'
                                        onClick={this.handleClickTotalISO_Back}
                                        fill={this.state.selectedISO_Color}
                                    />
                                    <Brush dataKey='date' />
                                </BarChart>
                            </ResponsiveContainer>
                        </>
                        : null}
            </>
        )
    }
    render() {
        return (<this.ReusableGraphISOTotalUI />)
    }
}
export default ReusableGraphISOTotal;