import React from 'react';
import FilterControl from '../../elements/FilterControl/FilterControl';


class GrossMarginByAccountFilterControl extends FilterControl{
    constructor(props) {
        super(props);

        this.options = this.getUniqueContractTypes(this.props.grossMarginChartData);
        this.dateRanges = this.getDateRanges();

    }

    componentDidMount() {
    }
    getDateRanges() {
        var dateRanges = [{
            label: 'Contract Sign Date'
        },
        {   
            label: 'Contract Start Date'
        },
        {
            label: 'Contract End Date'
        }]
        return dateRanges
    }

    getUniqueContractTypes(data) {

        var unique = data.children.map(iso => {
            return iso.children.map( edc => {return edc.children.map(account => {return account.type})}).flat()
        }).flat(1)
        unique = [...new Set(unique)].map(r => {return {label: r}})
        return unique;
    }

    printChecklist(checklist){
        console.log(JSON.stringify(Array.from(checklist.entries())));
    }

    render() {
        return (
            <FilterControl options = {this.options} optionsHeaderLabel = "Contract Types" 
                dateRanges = {this.dateRanges} dateRangeLabel = "Dates"
                loaded={true} FilterLabel="Filter Results" onChange={this.props.onChange}/>
        )
    }
    //This guy should take the dataset and pass it through.

    //This guy should take the dataset and perform the filters

    //This guy should have a callback when the filter changes

}


export default GrossMarginByAccountFilterControl