import React, { Component } from 'react';
import {
    XAxis, YAxis, Tooltip, CartesianGrid, Brush, ResponsiveContainer, Bar, BarChart, Cell, Legend, ComposedChart,
    PieChart, Pie, Sector
} from 'recharts';
import { calculatePercentage } from '../../utils/helper'
// @material-ui/core components
import { aggregateValue, aggregateValue_forGraph } from 'elements/ReactTableReUseAbleComponent/MaterialUITable';
import { CustomizedAxisTick, CustomYAxisDollar } from '../../utils/rechartHelper'
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { CSVLink } from "react-csv";
import { ColorButton, ColorRadioButton, ColorFormlabel } from '../../utils/materialUI_Reuseable_Comp'
const useStyles = makeStyles(styles);
class GrossMarginISOandItsCompany extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            totalVolumeISO_Hide: true,
        };

    }
    onPieEnter_EDC = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    }
    renderActiveShape_EDC = (props) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;
        return (
            <g>
                <text x={cx} y={cy} dy={-40} textAnchor="middle" fill={fill}>ISO: {payload.iso}</text>
                <text x={cx} y={cy} dy={-20} textAnchor="middle" fill={fill}>Total Value: {aggregateValue_forGraph(payload.Total_ISO_Value, true)}</text>
                <text x={cx} y={cy} dy={0} textAnchor="middle" fill={fill}>EDC: {payload.isoChild == null ? payload.isoChild : payload.isoChild}</text>
                <text x={cx} y={cy} dy={20} textAnchor="middle" fill={fill}>EDC Value:
                {aggregateValue_forGraph(payload.ISO_Company_Value, true)}</text>
                <text x={cx} y={cy} dy={40} textAnchor="middle" fill={fill}>
                    Total Accounts: {payload.ISO_Company_Total_Account}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
            </g>
        );
    };
    lable_PieChart = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, outerRadius, fill, payload } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 30) * cos;
        const sy = cy + (outerRadius + 30) * sin;
        const mx = cx + (outerRadius + 60) * cos;
        const my = cy + (outerRadius + 60) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 40;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';
        return (
            <g>
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${payload.isoChild}`}</text>
            </g>
        )
    }
    handleClickTotalISO_Back = () => {

        this.setState({
            totalVolumeISO_Hide: true,
        })
    }
    GrossMarginISOandItsCompanyUI = (grossMarginChartData) => {
        var grossMarginChartData1 = grossMarginChartData.grossMarginChartData
        var grossMarginChartData1_KeyValuePair = [], grossMarginChartData1_KeyValuePair_ISO = [], selected_ISO_EDC_Data = []
        const [graphViewState, setGraphViewState] = React.useState('0');
        const [selected_ISO_Name, setSelected_ISO_Name] = React.useState(null);

        const classes = useStyles();
        //console.log(selected_ISO_Name)
        const CustomToolTip = (props) => {
            const { active, payload } = props;
            var data = 0;
            // console.log(payload)
            if (!active || !payload) {
                return null;
            }
            else {
                data = payload[0].payload;
                //   console.log(data)
                return (
                    <>
                        <Card>
                            <CardHeader>
                                <strong style={{ color: data.isoColor }}>
                                    ISO: {data.iso == null ? data.name : data.iso}
                                    <br /> Total Value: {aggregateValue(data.Total_ISO_Value, true)}
                                    <br /> EDC: {data.isoChild == null ? data.isoChild : data.isoChild}
                                    <br /> EDC Value: {aggregateValue(data.ISO_Company_Value, true)}
                                    <br /> Total Accounts: {data.ISO_Company_Total_Account}
                                    <br /> Percentage($): {parseFloat(calculatePercentage(data.ISO_Company_Value, data.Total_ISO_Value)).toFixed(2)}%
                            </strong>
                            </CardHeader>
                        </Card>
                    </>
                );
            }
        };
        const handleViewGraphChnage = event => {
            setGraphViewState(event.target.value);
        };
        const onClick_ISO = (props) => {
            const { payload } = props;
            // console.log(payload)
            setSelected_ISO_Name(payload.iso)
            this.setState({ totalVolumeISO_Hide: false, })
        }
        if (grossMarginChartData1 != null) {
            grossMarginChartData1.children.map(a => {
                a.children.map(b => {
                    grossMarginChartData1_KeyValuePair.push({
                        iso: a.name, // return name of iso
                        Total_ISO_Value: a.value, // return value for iso
                        isoColor: a.color, // return value for iso
                        isoColor1: '#0000ff',
                        isoChild: b.name,
                        ISO_Company_Value: b.value,
                        ISO_Company_Total_Account: b.children.length,
                    })
                    //console.log(a)
                    if (selected_ISO_Name === a.name) {
                        selected_ISO_EDC_Data.push({
                            iso: a.name, // return name of iso
                            Total_ISO_Value: a.value, // return value for iso
                            isoColor: a.color, // return value for iso
                            isoColor1: '#0000ff',
                            isoChild: b.name,
                            ISO_Company_Value: b.value,
                            ISO_Company_Total_Account: b.children.length,
                        })
                    }
                    return null
                })
                return null
            })
            //console.log(grossMarginChartData1_KeyValuePair)
        }
        if (grossMarginChartData1 != null) {
            grossMarginChartData1.children.map(a => {
                return grossMarginChartData1_KeyValuePair_ISO.push({
                    iso: a.name, // return name of iso
                    TotalValue: a.value, // return value for iso
                    isoColor: a.color, // return value for iso
                })
            })
        }
        //  console.log(selected_ISO_EDC_Data)
        return (
            <>

                <FormControl component="fieldset" className={classes.formControl}>
                    <ColorFormlabel component="legend">Please Select the View</ColorFormlabel>
                    <RadioGroup aria-label="gender"
                        name="gender1"
                        value={graphViewState}
                        onChange={handleViewGraphChnage}
                        style={{ display: 'inline', flexDirection: 'row' }}>
                        <FormControlLabel value="1" control={<ColorRadioButton />} label="Pie Chart" />
                        <FormControlLabel value="0" control={<ColorRadioButton />} label="Bar Chart" />
                    </RadioGroup>
                </FormControl>
                <Button variant="contained">
                    <CSVLink data={grossMarginChartData1_KeyValuePair}
                        filename={"test.csv"}
                        target="_blank" >
                        Download Data</CSVLink>
                </Button>
                {
                    graphViewState === '1' ?
                        <ResponsiveContainer height={500} width="100%">
                            <PieChart>
                                <Pie data={grossMarginChartData1_KeyValuePair_ISO}
                                    dataKey="TotalValue"
                                    activeIndex={this.state.activeIndex}
                                    //activeShape={this.renderActiveShape}
                                    innerRadius={180}
                                    outerRadius={200}
                                    paddingAngle={10}
                                    onClick={onClick_ISO}
                                >
                                    {grossMarginChartData1_KeyValuePair_ISO.map((entry) => <Cell fill={entry.isoColor} key={entry.iso} />)}

                                </Pie>

                                <Pie data={selected_ISO_EDC_Data}
                                    dataKey="ISO_Company_Value"
                                    activeIndex={this.state.activeIndex}
                                    activeShape={this.renderActiveShape_EDC}
                                    innerRadius={120}
                                    outerRadius={160}
                                    paddingAngle={2}
                                    onMouseEnter={this.onPieEnter_EDC}
                                    label={this.lable_PieChart}
                                >
                                    {selected_ISO_EDC_Data.map((entry) => <Cell fill={entry.isoColor} key={entry.iso} />)}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                        : graphViewState === '0' ?
                            <>
                                {this.state.totalVolumeISO_Hide === true ?
                                    <><br></br>*Please Select ISO to View Its Details by EDCs<br></br>
                                        < ResponsiveContainer height={500} width="100%">
                                            <BarChart data={grossMarginChartData1_KeyValuePair_ISO}
                                                margin={{ top: 10, right: 5, left: 25, bottom: 0 }}
                                            >
                                                <CartesianGrid strokeDasharray="1 1" />
                                                <XAxis dataKey="iso" height={60} tick={<CustomizedAxisTick />} />
                                                <YAxis tick={<CustomYAxisDollar />} />
                                                <Tooltip content={<CustomToolTip />} />
                                                <Bar type="monotone" dataKey="TotalValue" barSize={20} onClick={onClick_ISO}>
                                                    {
                                                        grossMarginChartData1_KeyValuePair_ISO.map((entry, index) => {
                                                            return <Cell key={`cell-${index}`} fill={entry.isoColor} /*dataKey={entry.value}*/ />
                                                        })
                                                    }
                                                </Bar>
                                                <Brush />
                                            </BarChart>
                                        </ResponsiveContainer>
                                        <ComposedChart layout="vertical" width={600} height={200} data={grossMarginChartData1_KeyValuePair_ISO}
                                            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                                            <CartesianGrid stroke='#f5f5f5' />
                                            <XAxis tick={<CustomYAxisDollar />} type="number" />
                                            <YAxis dataKey="iso" height={60} tick={<CustomizedAxisTick />}type="category" />
                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey='TotalValue' barSize={20} fill='#413ea0' >
                                                {
                                                    grossMarginChartData1_KeyValuePair_ISO.map((entry, index) => {
                                                        return <Cell key={`cell-${index}`} fill={entry.isoColor} /*dataKey={entry.value}*/ />
                                                    })
                                                }
                                            </Bar>
                                        </ComposedChart>
                                    </> : this.state.totalVolumeISO_Hide === false ?
                                        <><br></br>
                                            <ColorButton variant="contained" color="primary" className={classes.margin}
                                                onClick={this.handleClickTotalISO_Back}>
                                                Back up to ISO Level</ColorButton><br></br>
                                            < ResponsiveContainer height={500} width="100%">
                                                <BarChart data={selected_ISO_EDC_Data}
                                                    margin={{ top: 10, right: 5, left: 25, bottom: 0 }}
                                                >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="isoChild" height={60} tick={<CustomizedAxisTick />} />
                                                    <YAxis tick={<CustomYAxisDollar />} />
                                                    <Tooltip content={<CustomToolTip />} />
                                                    <Bar type="monotone" dataKey="ISO_Company_Value" barSize={20} onClick={onClick_ISO}>
                                                        {
                                                            selected_ISO_EDC_Data.map((entry, index) => {
                                                                return <Cell key={`cell-${index}`} fill={entry.isoColor} /*dataKey={entry.value}*/ />
                                                            })
                                                        }
                                                    </Bar>
                                                    <Brush />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </>
                                        : null}
                            </>
                            : null
                }
            </>
        )
    }
    render() {
        //console.log(this.props.grossMarginChartData)
        // console.log(this.props.grossMarginByAccount)
        return (<this.GrossMarginISOandItsCompanyUI
            grossMarginChartData={this.props.grossMarginChartData}
        />)
    }
}
export default GrossMarginISOandItsCompany;