//import React from "react";
//import classNames from "classnames";
//import { makeStyles } from "@material-ui/core/styles";
// core components
//import Button from "components/CustomButtons/Button.js";
//import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
//const useStyles = makeStyles(styles);
export default function HeaderLinks() {
  /*const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const classes = useStyles();
  const managerClasses = classNames({
    [classes.managerClasses]: true
  });*/
  /*return (
    <div className={managerClasses}>
      <Button
        color="transparent"
        aria-label="Person"
        justIcon
        aria-owns={openProfile ? "profile-menu-list" : null}
        //aria-haspopup="true"
        onClick={handleClickProfile}
        value={0}
        className={classes.buttonLink}
      />
    </div>
  );*/
  return null
}
