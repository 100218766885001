import React from 'react';
import {
    XAxis, YAxis, Tooltip, CartesianGrid, Legend,
    Line, Brush, LineChart, ResponsiveContainer, Area, AreaChart, Bar,
    BarChart
} from 'recharts';
import { CustomToolTipMWh, CustomizedAxisTick, CustomToolTipDollar, CustomYAxisMWh, CustomYAxisDollar } from '../../utils/rechartHelper'

export default function ReusableGraphGrossMarginVolume({ data_to_drawGraph, graphViewSelection, View_Dollar_Graph }) {
    //console.log(View_Dollar_Graph)
    //console.log(data_to_drawGraph)
    return (
        <>
            <ResponsiveContainer height={500}>
                {graphViewSelection === '0' ?
                    <LineChart data={data_to_drawGraph}
                        margin={{ top: 10, right: 5, left: 60, bottom: 0 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" height={60} tick={<CustomizedAxisTick />} />
                        <YAxis tick={View_Dollar_Graph === true ? <CustomYAxisDollar /> : <CustomYAxisMWh />} />
                        <Tooltip content={View_Dollar_Graph === true ? <CustomToolTipDollar /> : <CustomToolTipMWh />} />
                        <Legend />
                        {data_to_drawGraph.map(a => (
                            a.ERCOTName != null ? <Line type="monotone" name={a.ERCOTName} dataKey='ERCOT' stroke={a.ERCOTcolor} fill={a.ERCOTcolor} /> : null
                        ))[0]}
                        {data_to_drawGraph.map(a => (
                            a.ISONEName != null ? <Line type="monotone" name={a.ISONEName} dataKey='ISONE' stroke={a.ISONEcolor} fill={a.ISONEcolor} /> : null
                        ))[0]}
                        {data_to_drawGraph.map(a => (
                            a.MISOName != null ? <Line type="monotone" name={a.MISOName} dataKey='MISO' stroke={a.MISOcolor} fill={a.MISOcolor} /> : null
                        ))[0]}
                        {data_to_drawGraph.map(a => (
                            a.NYISOName != null ? <Line type="monotone" name={a.NYISOName} dataKey='NYISO' stroke={a.NYISOcolor} fill={a.NYISOcolor} /> : null
                        ))[0]}
                        {data_to_drawGraph.map(a => (
                            a.PJMName != null ? <Line type="monotone" name={a.PJMName} dataKey='PJM' stroke={a.PJMcolor} fill={a.PJMcolor} /> : null
                        ))[0]}
                        <Brush />
                    </LineChart>
                    : graphViewSelection === '1' ?
                        <AreaChart data={data_to_drawGraph}
                            margin={{ top: 10, right: 5, left: 60, bottom: 0 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis height={60} dataKey='date' tick={<CustomizedAxisTick />} />
                            <YAxis tick={View_Dollar_Graph === true ? <CustomYAxisDollar /> : <CustomYAxisMWh />} />
                            <Tooltip content={View_Dollar_Graph === true ? <CustomToolTipDollar /> : <CustomToolTipMWh />} />
                            <Legend />stackId="1"
                            {data_to_drawGraph.map(a => (
                                a.ERCOTName != null ? <Area type="monotone" name={a.ERCOTName} dataKey='ERCOT' stroke={a.ERCOTcolor} fill={a.ERCOTcolor} /> : null
                            ))[0]}
                            {data_to_drawGraph.map(a => (
                                a.ISONEName != null ? <Area type="monotone" name={a.ISONEName} dataKey='ISONE' stroke={a.ISONEcolor} fill={a.ISONEcolor} /> : null
                            ))[0]}
                            {data_to_drawGraph.map(a => (
                                a.MISOName != null ? <Area type="monotone" name={a.MISOName} dataKey='MISO' stroke={a.MISOcolor} fill={a.MISOcolor} /> : null
                            ))[0]}
                            {data_to_drawGraph.map(a => (
                                a.NYISOName != null ? <Area type="monotone" name={a.NYISOName} dataKey='NYISO' stroke={a.NYISOcolor} fill={a.NYISOcolor} /> : null
                            ))[0]}
                            {data_to_drawGraph.map(a => (
                                a.PJMName != null ? <Area type="monotone" name={a.PJMName} dataKey='PJM' stroke={a.PJMcolor} fill={a.PJMcolor} /> : null
                            ))[0]}
                            <Brush />
                        </AreaChart>
                        :
                        <BarChart
                            data={data_to_drawGraph}
                            margin={{ top: 10, right: 5, left: 60, bottom: 0 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis height={60} dataKey='date' tick={<CustomizedAxisTick />} />
                            <YAxis tick={View_Dollar_Graph === true ? <CustomYAxisDollar /> : <CustomYAxisMWh />} />
                            <Tooltip content={View_Dollar_Graph === true ? <CustomToolTipDollar /> : <CustomToolTipMWh />} />
                            <Legend />
                            {data_to_drawGraph.map(a => (
                                a.ERCOTName != null ? <Bar type="monotone" name={a.ERCOTName} dataKey='ERCOT' stroke={a.ERCOTcolor} fill={a.ERCOTcolor} /> : null
                            ))[0]}
                            {data_to_drawGraph.map(a => (
                                a.ISONEName != null ? <Bar type="monotone" name={a.ISONEName} dataKey='ISONE' stroke={a.ISONEcolor} fill={a.ISONEcolor} /> : null
                            ))[0]}
                            {data_to_drawGraph.map(a => (
                                a.MISOName != null ? <Bar type="monotone" name={a.MISOName} dataKey='MISO' stroke={a.MISOcolor} fill={a.MISOcolor} /> : null
                            ))[0]}
                            {data_to_drawGraph.map(a => (
                                a.NYISOName != null ? <Bar type="monotone" name={a.NYISOName} dataKey='NYISO' stroke={a.NYISOcolor} fill={a.NYISOcolor} /> : null
                            ))[0]}
                            {data_to_drawGraph.map(a => (
                                a.PJMName != null ? <Bar type="monotone" name={a.PJMName} dataKey='PJM' stroke={a.PJMcolor} fill={a.PJMcolor} /> : null
                            ))[0]}
                            <Brush />
                        </BarChart>
                }
            </ResponsiveContainer>
        </>
    )
}