import React from 'react';

const ProtectedLayout = ({
    component: Component,
    authState,
    ...rest
}) => {
    if (authState === 'signedIn') {
        return <Component {...rest} />;
    }
    return null;
};

export default ProtectedLayout;