import { isoColor } from '../../utils/helper';

export default function RearrangeGraphISOTotal(GrossMargin_Volume_Total, yearLists) {
    var total_keypair = []
    if (GrossMargin_Volume_Total !== null) {
        total_keypair = []
        GrossMargin_Volume_Total.map(a => {
            // gross margin
            if (a.type === "gross margin") {
                ///console.log(a.values)
                var sum_margin = 0
                yearLists.map(b => {
                    //console.log(a.values['2024'])
                    if (a.values[b.year] !== undefined) {
                        // console.log(b.year)
                        // console.log(a.values[b.year].yearly)
                        sum_margin += a.values[b.year].yearly
                    }
                    return null
                })
                // console.log(sum_margin)
                total_keypair.push({
                    iso: a.iso,
                    value: sum_margin,
                    color: isoColor(a.iso),
                    type: 'dollar'
                })
            }

            //volume
            if (a.type === "volume") {
                //console.log(a)
                var sum_volume = 0
                yearLists.map(b => {
                    if (a.values[b.year] !== undefined) {
                        sum_volume += a.values[b.year].yearly
                    }
                    return null
                })
                total_keypair.push({
                    iso: a.iso,
                    value: sum_volume, // total volume of each iso
                    allValues: a.values, // volume of each iso by year and month
                    color: isoColor(a.iso),
                    type: 'volume'
                })
            }
            return null
        })
    }
    return total_keypair
}
