import React, { Component } from 'react';
import EDCDataSelector from '../../elements/GrossMarginVolumeByIsoLineGraph/EDCDataSelector'
import LinearProgress from '@material-ui/core/LinearProgress';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { ISO_ForwardGrossMarginQuery, ISOEDC_ForwardGrossMarginDataQuery } from "../../graphQL_Query/ForwardGrossMarginQuery"
import API, { graphqlOperation } from "@aws-amplify/api";
import { isoColor } from '../../utils/helper';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { ColorRadioButton, ColorFormlabel } from '../../utils/materialUI_Reuseable_Comp'
import GrossMarginByIsoLineGraph from '../../elements/GrossMarginVolumeByIsoLineGraph/GrossMarginByIsoLineGraph'
import VolumeByIsoLineGraph from '../../elements/GrossMarginVolumeByIsoLineGraph/VolumeByIsoLineGraph';
import EDCbyVolumeMargin from '../../elements/GrossMarginVolumeByIsoLineGraph/EDCbyVolumeMargin'
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);
class ForwardGrossMarginOverTime extends Component {
    constructor() {
        super();
        this.state = {
            //ISO Data new graphql query
            getGraphQL_ForwardMargin_ISO: null,
            getGraphQL_ForwardMargin_Format_ISO: null,
            getGraphQL_GrossMargin_ISO: null,
            getGraphQL_Volume_ISO: null,
            //ISO EDC Data new graphql query 
            getGraphQL_ForwardMargin_ISO_EDC: null,
            getGraphQL_ForwardMargin_Format_ISO_EDC: null,
            getGraphQL_GrossMargin_ISO_EDC: null,
            getGraphQL_Volume_ISO_EDC: null,
            //EDC Data for table
            getGraphQL_ForwardMargin_EDC_Table: null,
            getGraphQL_ForwardMargin_Format_EDC_Table: null,

            generateYearList: [],
        }
    };

    componentDidMount() {
        // get the data for gross margin and volume iso graph by monthly 
        API.graphql(graphqlOperation(ISO_ForwardGrossMarginQuery))
            .then(({ data: getEdcData }) => {
                if (getEdcData.getMonthlyData !== null) {
                    var getGraphQL_ForwardMargin_ISO = getEdcData.getMonthlyData
                    //console.log(getGraphQL_ForwardMargin_ISO)
                    this.GraphQLReformatData_Yearly_Monthly_ISO(getGraphQL_ForwardMargin_ISO);
                }
            });
        // get the data for gross margin and volume for EDC graph by monthly 
        API.graphql(graphqlOperation(ISOEDC_ForwardGrossMarginDataQuery))
            .then(({ data: getEdcData }) => {
                if (getEdcData.getMonthlyData !== null) {
                    var getGraphQL_ForwardMargin_ISO_EDC = getEdcData.getMonthlyData
                    //console.log(getGraphQL_ForwardMargin_ISO_EDC)
                    this.GraphQLReformatData_Yearly_Monthly_ISO_EDC(getGraphQL_ForwardMargin_ISO_EDC);
                }
            });
    }
    // reformat the data for ISO graph by monthly 
    GraphQLReformatData_Yearly_Monthly_ISO = (getGraphQL_ForwardMargin_ISO) => {
        var GraphQL_Volume = [], GraphQL_GrossMargin = []
        var YearList = [], generateYearList = []
        getGraphQL_ForwardMargin_ISO.forEach(row => {
            let newValues = [];
            row.values.forEach(val => newValues[val.year] = val.values.reduce((a, vy) => {
                a.yearly += vy.value;
                YearList.push(val.year)
                a.monthly[vy.month] = vy.value;
                return a;
            }, { yearly: 0, monthly: {} }))
            row.values = newValues; // assign formated data 
            row.color = isoColor(row.iso) // assign color for each iso
            newValues = []
            if (row.type === "volume") {
                if (row !== null) {
                    GraphQL_Volume.push(row)
                }
            }
            //extract value of edc with only gross margin
            if (row.type === "gross margin") {
                if (row !== null) {
                    GraphQL_GrossMargin.push(row)
                }
            }
        });
        const uniqueYears = YearList.filter((val, id, array) => array.indexOf(val) === id);
        uniqueYears.sort((a, b) => a - b)
        for (let i = 0; i < uniqueYears.length; i++) {
            generateYearList.push({
                year: uniqueYears[i],
                id: i
            })
        }
        // console.log(uniqueYears)
        //console.log(generateYearList)
        //console.log(getGraphQL_ForwardMargin_ISO)
        // console.log(GraphQL_GrossMargin)
        // console.log(GraphQL_Volume)
        this.setState({
            getGraphQL_ForwardMargin_Format_ISO: getGraphQL_ForwardMargin_ISO,
            getGraphQL_Volume_ISO: GraphQL_Volume,
            getGraphQL_GrossMargin_ISO: GraphQL_GrossMargin,
            generateYearList: generateYearList
        })
    };
    // reformat the data for EDC graph by monthly 
    GraphQLReformatData_Yearly_Monthly_ISO_EDC = (getGraphQL_ForwardMargin_ISO_EDC) => {
        var GraphQL_Volume_ISO_EDC = [], GraphQL_GrossMargin_ISO_EDC = []
        var YearList = [], generateYearList = []
        getGraphQL_ForwardMargin_ISO_EDC.forEach(row => {
            let newValues = [];
            row.edcs.forEach(a => {
                // console.log(a)
                a.values.map(b => newValues[b.year] = b.values.reduce((a, vy) => {
                    // console.log(a)
                    a.yearly += vy.value;
                    YearList.push(b.year)
                    a.monthly[vy.month] = vy.value;
                    return a;
                }, { yearly: 0, monthly: {} }))
                a.values = newValues; // calculate value by yearly for each edc 
                newValues = []
            })
            row.color = isoColor(row.iso) // assign color for each iso
            if (row.type === "volume") {
                if (row !== null) {
                    GraphQL_Volume_ISO_EDC.push(row)
                }
            }
            //extract value of edc with only gross margin
            if (row.type === "gross margin") {
                if (row !== null) {
                    GraphQL_GrossMargin_ISO_EDC.push(row)
                }
            }
        });
        const uniqueYears = YearList.filter((val, id, array) => array.indexOf(val) === id);
        uniqueYears.sort((a, b) => a - b)
        for (let i = 0; i < uniqueYears.length; i++) {
            generateYearList.push({
                year: uniqueYears[i],
                id: i
            })
        }
        // console.log(uniqueYears)
        //console.log(generateYearList)
        this.GraphQLReformatData_EDC_Table(getGraphQL_ForwardMargin_ISO_EDC) // send data to format data for table to display edc
        // console.log(getGraphQL_ForwardMargin_ISO_EDC)
        // console.log(GraphQL_GrossMargin_ISO_EDC)
        //  console.log(GraphQL_Volume_ISO_EDC)
        this.setState({
            getGraphQL_ForwardMargin_Format_ISO_EDC: getGraphQL_ForwardMargin_ISO_EDC,
            getGraphQL_Volume_ISO_EDC: GraphQL_Volume_ISO_EDC,
            getGraphQL_GrossMargin_ISO_EDC: GraphQL_GrossMargin_ISO_EDC,
            generateYearList: generateYearList
        })
    };
    // reformat the data for EDC graph by monthly 
    GraphQLReformatData_EDC_Table = (getGraphQL_ForwardMargin_EDC_Table) => {
        var getGraphQL_ForwardMargin_EDC_Table_KeyPair = []
        getGraphQL_ForwardMargin_EDC_Table.map(a => {
            a.edcs.map(b => {
                getGraphQL_ForwardMargin_EDC_Table_KeyPair.push({
                    iso: a.iso,
                    type: a.type,
                    edcName: b.name,
                    color: isoColor(a.iso),
                    values: b.values
                })
                return null
            })
            return null
        })
        //  console.log(getGraphQL_ForwardMargin_EDC_Table)
        //  console.log(getGraphQL_ForwardMargin_EDC_Table_KeyPair)
        this.setState({
            getGraphQL_ForwardMargin_Format_EDC_Table: getGraphQL_ForwardMargin_EDC_Table_KeyPair,
        })
    };
    changeView(e) {
        const v = e.currentTarget.getAttribute('value');
        this.setState({
            view: v
        });
    }
    ForwardGrossMarginOverTimeUI = () => {
        const classes = useStyles();
        const [graphViewState, setGraphViewState] = React.useState('0');
        const handleViewGraphChnage = event => {
            setGraphViewState(event.target.value);
        };
        return (

            <>
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color="success" icon>
                                <CardIcon color="success">
                                    <RemoveRedEyeIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                    Forward Gross Margin & Volume Over Time of ISO </h4>
                            </CardHeader>
                            <CardBody>
                                <>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <ColorFormlabel component="legend">Please Select the Type</ColorFormlabel>
                                        <RadioGroup aria-label="gender" name="gender1"
                                            value={graphViewState}
                                            onChange={handleViewGraphChnage}
                                            style={{ display: 'inline', flexDirection: 'row' }}>
                                            <FormControlLabel value="0" control={<ColorRadioButton />} label="Gross Margin($)" />
                                            <FormControlLabel value="1" control={<ColorRadioButton />} label="Volume(MWh)" />
                                        </RadioGroup>
                                    </FormControl>
                                    {graphViewState === '0' ?
                                        <>
                                            {!this.state.getGraphQL_GrossMargin_ISO ? <LinearProgress /> :
                                                this.state.generateYearList[0] !== undefined ?
                                                    <GrossMarginByIsoLineGraph
                                                        GraphQL_GrossMargin_Data_ISO={this.state.getGraphQL_GrossMargin_ISO}
                                                        yearList={this.state.generateYearList}
                                                    />
                                                    : null
                                            }
                                        </>
                                        : <>
                                            {!this.state.getGraphQL_Volume_ISO ? <LinearProgress /> :
                                                this.state.generateYearList[0] !== undefined ?
                                                    <VolumeByIsoLineGraph
                                                        GraphQL_Volume_Data_ISO={this.state.getGraphQL_Volume_ISO}
                                                        yearList={this.state.generateYearList}
                                                    />
                                                    : null
                                            }
                                        </>
                                    }
                                </>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader>
                                <h4 className={classes.cardTitle}>
                                    EDC Data by Gross Margin and Volume</h4>
                            </CardHeader>
                            <CardBody>
                                {this.state.generateYearList[0] !== undefined ?
                                    <EDCbyVolumeMargin
                                        getGraphQL_ForwardMargin_Format_EDC_Table={this.state.getGraphQL_ForwardMargin_Format_EDC_Table}
                                        yearList={this.state.generateYearList}
                                    />
                                    : null}
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color="success" icon>
                                <CardIcon color="success">
                                    <RemoveRedEyeIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                    Forward Gross Margin & Volume Over Time of EDC in ISO </h4>
                            </CardHeader>
                            <CardBody>
                                {this.state.generateYearList[0] !== undefined ?
                                    <EDCDataSelector
                                        GraphQL_GrossMargin_Data_ISO_EDC={this.state.getGraphQL_GrossMargin_ISO_EDC}
                                        GraphQL_Volume_Data_ISO_EDC={this.state.getGraphQL_Volume_ISO_EDC}
                                        yearList={this.state.generateYearList}
                                    />
                                    : null}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        )
    }
    render() {
        return (
            <this.ForwardGrossMarginOverTimeUI />
        )
    }
}
export default ForwardGrossMarginOverTime;
