import React, { Component } from 'react';
import {
    Area, XAxis, YAxis, Tooltip, CartesianGrid, Legend, Bar, AreaChart,
    BarChart, Brush, Cell, ResponsiveContainer
} from 'recharts';
import { monthNumber_to_Name } from '../../utils/helper'
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import DashboardKeyValuePairTable from '../../elements/ReactTableReUseAbleComponent/DashboardKeyValuePairTable.js';
import { CustomToolTipMWh, CustomizedAxisTick, CustomYAxisMWh, CustomToolTipDashboardVolume } from '../../utils/rechartHelper'
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { CSVLink } from "react-csv";
//import { getCompanyName } from '../../Logos/getCompanyName';
import {
    ColorButton, ColorRadioButton, ColorFormlabel, ColorButtonAlignRight
} from '../../utils/materialUI_Reuseable_Comp'
import { onClick_DownloadIamge, onClick_DownloadIamge_Second } from '../../utils/downloadGraphImage'
import { ListMonth, capitalize } from '../../utils/helper';
import { LinearProgress } from '@material-ui/core';
const useStyles = makeStyles(styles);
class VolumeStackedAreaChartContainer extends Component {
    constructor() {
        super();
        this.state = {
            testData: [],
            isoTotalData_KeyValuePair: [],
            isLoadingGraph: 0,
            startIndex: 0,
            endIndex: 10,
            interval: 0,
            totalVolumeISO_Hide: true,
            selectedISO_Name: null,
            selectedISO_Color: null,
            selectedISO_Data_KeyPair: [],
        }
    }

    handleClickTotalISO_Hide = (event) => {
        var Volume_Data_Select_ISO = this.props.volume_total
        const { yearList } = this.props;
        //console.log(Volume_Data_Select_ISO)
        var selectedISO_Data_KeyPair = [];
        //console.log(event.iso)
        //console.log(event.color)
        // console.log(event)
        Volume_Data_Select_ISO.map(a => {
            if (a.name === event.iso) {
                yearList.map(b => { // map year to extract data 
                    ListMonth.map(c => {//map month to extract data
                        let obj_volume = {};
                        obj_volume.date = capitalize(c.month) + ", " + b.year;

                        if (a.allValues[b.year] !== undefined) {
                            if (a.allValues[b.year].monthly[c.month] !== undefined) {
                                obj_volume["value"] = a.allValues[b.year].monthly[c.month]
                            } else {
                                obj_volume["value"] = 0
                            }
                        } else {
                            obj_volume["value"] = 0
                        }

                        selectedISO_Data_KeyPair.push(obj_volume)
                        return null
                    })
                    return null
                })
            }
            return null
        })
        //console.log("iso level click")
        //console.log(selectedISO_Data_KeyPair)
        this.setState({
            totalVolumeISO_Hide: false,
            selectedISO_Name: event.iso,
            selectedISO_Color: event.color,
            selectedISO_Data_KeyPair
        })
    }
    handleClickTotalISO_Back = () => {
        // console.log("back - total iso level click")
        this.setState({
            totalVolumeISO_Hide: true,
        })
    }
    //display UI
    VolumeStackedAreaChartContainerUI = () => {
        const { yearList } = this.props;
        const [selecteddate, setSelecteddate] = React.useState(new Date('2020-01-01'));
        const [graphViewState, setGraphViewState] = React.useState('0');
        const classes = useStyles();
        var Volume_Data = this.props.volume_total // import entire data of volume from porfoliometric container 
        var isoTotalDataVolume_KeyValuePair = [], isoTotal_Month_Year_KeyValuePair = [];
        let DynamicGraphDataAll = [], Clean_DynamicGraphData = []
        //console.log(Volume_Data)
        if (Volume_Data[0] !== undefined) {
            Volume_Data.map(a => {
                isoTotalDataVolume_KeyValuePair.push({
                    iso: a.name, // return name of iso
                    value: a.value, // return value for iso
                    color: a.color // return color for each iso
                })
                return null
            })
            // reformat data to display stacked bar chart and fill area chart and filter data by iso and month
            yearList.map(b => { // map year to extract data 
                ListMonth.map(c => {//map month to extract data
                    let obj_volume = {};
                    obj_volume.date = capitalize(c.month) + ", " + b.year;
                    Volume_Data.map(a => {
                        if (a.allValues[b.year] !== undefined) {
                            if (a.allValues[b.year].monthly[c.month] !== undefined) {
                                //console.log(a.name + ", " + capitalize(c.month) + ", " + b.year + " = " + a.allValues[b.year].monthly[c.month])
                                obj_volume[a.name] = a.allValues[b.year].monthly[c.month]
                            } else {
                                obj_volume[a.name] = 0
                            }
                        } else {
                            obj_volume[a.name] = 0
                        }
                        return null
                    })
                    isoTotal_Month_Year_KeyValuePair.push(obj_volume)
                    return null
                })
                return null
            })
        }
        //console.log(isoTotal_Month_Year_KeyValuePair)
        // this handle and diplay data on the basis of month and year selection 
        var handleDateChange = date => {
            var reformat_date = monthNumber_to_Name(date.getMonth()) + ", " + date.getFullYear()
            setSelecteddate(reformat_date);
            //console.log(reformat_date)
            isoTotal_Month_Year_KeyValuePair.map(a => {
                if (reformat_date === a.date) {
                    DynamicGraphDataAll.push(
                        a.ERCOT != null ? { name: 'ERCOT', value: a.ERCOT, color: '#D95F02' } : { name: 'Restricted', value: '--', color: '#D95F02' },
                        a.ISONE != null ? { name: 'ISONE', value: a.ISONE, color: '#E7298A' } : { name: 'Restricted', value: '--', color: '#E7298A' },
                        a.MISO != null ? { name: 'MISO', value: a.MISO, color: '#666666' } : { name: 'Restricted', value: '--', color: '#666666' },
                        a.NYISO != null ? { name: 'NYISO', value: a.NYISO, color: '#1B9E77' } : { name: 'Restricted', value: '--', color: '#1B9E77' },
                        a.PJM != null ? { name: 'PJM', value: a.PJM, color: '#389FD3' } : { name: 'Restricted', value: '--', color: '#389FD3' },
                    );
                    DynamicGraphDataAll.map(a => {
                        if (a.name === 'Restricted') { } else {
                            Clean_DynamicGraphData.push(a)
                        }
                        return null
                    })
                    this.setState({ testData: Clean_DynamicGraphData, isLoadingGraph: 1 })
                    //console.log('date match')
                }
                return null
            })
        };
        const handleViewGraphChnage = event => {
            setGraphViewState(event.target.value);
        };
        //console.log(this.state.selectedISO_Data_KeyPair)
        /*1st and 2nd grid daw the bar chart with total of all iso mwh with table
        3rd grid draw stacked graph and tranparent graph on the basis of user selection and show data by monthly and yearly
        4th grid will draw bar graph on the basis of user date selection */
        return (
            <>
                <GridItem xs={12} sm={6} md={8}>
                    <Card chart className={classes.cardHover}>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Graph with Total Volume of ISO's &nbsp; <ColorButtonAlignRight variant="contained" color="primary" className={classes.margin} onClick={onClick_DownloadIamge}>Download Graph</ColorButtonAlignRight>                            </h4>
                        </CardBody>
                        <CardHeader>
                            {this.state.totalVolumeISO_Hide === true ?
                                <>{isoTotalDataVolume_KeyValuePair[0] !== undefined ?
                                    <>*Please Select ISO to View Its Details by Monthly<br></br>
                                        <div id="my-node">
                                            <ResponsiveContainer height={310}>
                                                <BarChart data={isoTotalDataVolume_KeyValuePair} margin={{ top: 0, right: 40, left: 60, bottom: 0 }}>
                                                    <XAxis dataKey='iso' tick={<CustomizedAxisTick />} />
                                                    <YAxis tick={<CustomYAxisMWh />} />
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <Tooltip content={<CustomToolTipDashboardVolume />} />
                                                    <Bar maxBarSize={50} dataKey='value' onClick={this.handleClickTotalISO_Hide}
                                                    >
                                                        {
                                                            isoTotalDataVolume_KeyValuePair.map((entry, index) => {
                                                                return <Cell key={`cell-${index}`} fill={entry.color} /*dataKey={entry.value}*/ />
                                                            })
                                                        }
                                                    </Bar>
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </> : <LinearProgress />}
                                </>
                                : this.state.totalVolumeISO_Hide === false ?
                                    <>
                                        <ColorButton variant="contained"
                                            onClick={this.handleClickTotalISO_Back}>
                                            Back up to ISO Level </ColorButton>
                                        <div id="my-node">
                                            <ResponsiveContainer height={400}>
                                                <BarChart data={this.state.selectedISO_Data_KeyPair}
                                                    margin={{ top: 20, right: 5, left: 60, bottom: 20 }}>
                                                    <XAxis height={60} dataKey='date' tick={<CustomizedAxisTick />} />
                                                    <YAxis tick={<CustomYAxisMWh />} />
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <Tooltip content={<CustomToolTipDashboardVolume />} />
                                                    <Bar barSize={5} dataKey='value'
                                                        onClick={this.handleClickTotalISO_Back}
                                                        fill={this.state.selectedISO_Color}
                                                    />
                                                    <Brush dataKey='date' />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </>
                                    : null}
                        </CardHeader>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card chart className={classes.cardHover}>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Table with Total Volume of ISO's</h4>
                        </CardBody>
                        <CardHeader>
                            {this.props.volume_total[0] !== undefined ?
                                <DashboardKeyValuePairTable
                                    table_data={this.props.volume_total}
                                    condition={false}
                                /> : <LinearProgress />}
                        </CardHeader>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={8}>
                    <Card chart className={classes.cardHover}>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Graph to View Data by Month and Year of Each ISO
                            &nbsp; <ColorButtonAlignRight variant="contained" color="primary" className={classes.margin} onClick={onClick_DownloadIamge_Second}>Download Graph</ColorButtonAlignRight>
                            </h4>
                        </CardBody>

                        <CardHeader>
                            {isoTotal_Month_Year_KeyValuePair[0] && this.props.volume_total[0] !== undefined ?
                                <>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <ColorFormlabel component="legend">Please Select the View</ColorFormlabel>
                                        <RadioGroup aria-label="gender"
                                            name="gender1"
                                            value={graphViewState}
                                            onChange={handleViewGraphChnage}
                                            style={{ display: 'inline', flexDirection: 'row' }}>
                                            <FormControlLabel value="0" control={<ColorRadioButton />} label="Stacked Bar Chart View" />
                                            <FormControlLabel value="1" control={<ColorRadioButton color="primary" />} label="Transparent Area Fill View" />
                                        </RadioGroup>
                                    </FormControl>
                                    <ColorButtonAlignRight variant="contained" color="primary" className={classes.margin} >
                                        <CSVLink data={isoTotal_Month_Year_KeyValuePair} style={{ color: 'white' }}
                                            filename={"Monthly_Volume.csv"}
                                            target="_blank">
                                            Download CSV</CSVLink>
                                    </ColorButtonAlignRight>
                                    {graphViewState === '0' ?
                                        <>
                                            <div id="my-node2">
                                                <ResponsiveContainer height={500}>
                                                    <BarChart
                                                        data={isoTotal_Month_Year_KeyValuePair}
                                                        margin={{ top: 20, right: 5, left: 60, bottom: 5 }}>
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis height={60} dataKey='date' tick={<CustomizedAxisTick />} />
                                                        <YAxis tick={<CustomYAxisMWh />} />
                                                        <Tooltip content={<CustomToolTipMWh />} />
                                                        <Legend />
                                                        {isoTotal_Month_Year_KeyValuePair.map(a => {
                                                            return a.ERCOT != null ? <Bar type="monotone" stackId="a" name='ERCOT' dataKey='ERCOT' fill='#D95F02' /> : null
                                                        })[0]}
                                                        {isoTotal_Month_Year_KeyValuePair.map(a => (
                                                            a.ISONE != null ? <Bar name='ISONE' stackId="a" dataKey='ISONE' fill='#E7298A' /> : null
                                                        ))[0]}
                                                        {isoTotal_Month_Year_KeyValuePair.map(a => (
                                                            a.MISO != null ? <Bar name='MISO' stackId="a" dataKey='MISO' fill='#666666' /> : null
                                                        ))[0]}
                                                        {isoTotal_Month_Year_KeyValuePair.map(a => (
                                                            a.NYISO != null ? <Bar name='NYISO' stackId="a" dataKey='NYISO' fill='#1B9E77' /> : null
                                                        ))[0]}
                                                        {isoTotal_Month_Year_KeyValuePair.map(a => (
                                                            a.PJM != null ? <Bar name='PJM' stackId="a" dataKey='PJM' fill='#389FD3' /> : null
                                                        ))[0]}
                                                        <Brush
                                                            dataKey='date'
                                                        /*height={20}
                                                        stroke="#000000"
                                                        startIndex={0}
                                                        endIndex={12}*/
                                                        />

                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </> :
                                        <>
                                            <div id="my-node2">
                                                <ResponsiveContainer height={500}>
                                                    <AreaChart data={isoTotal_Month_Year_KeyValuePair}
                                                        margin={{ top: 10, right: 5, left: 60, bottom: 0 }}>
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis height={60} dataKey='date' tick={<CustomizedAxisTick />} />
                                                        <YAxis tick={<CustomYAxisMWh />} />
                                                        <Tooltip content={<CustomToolTipMWh />} />
                                                        <Legend />
                                                        {isoTotal_Month_Year_KeyValuePair.map(a => {
                                                            // console.log(a)
                                                            return a.ERCOT != null ? <Area type="monotone" stackId="1" name='ERCOT' dataKey='ERCOT' stroke='#D95F02' fill='#D95F02' /> : null
                                                        })[0]}
                                                        {isoTotal_Month_Year_KeyValuePair.map(a => (
                                                            a.ISONE != null ? <Area type="monotone" stackId="1" name='ISONE' dataKey='ISONE' stroke='#E7298A' fill='#E7298A' /> : null
                                                        ))[0]}
                                                        {isoTotal_Month_Year_KeyValuePair.map(a => (
                                                            a.MISO != null ? <Area type="monotone" stackId="1" name='MISO' dataKey='MISO' stroke='#666666' fill='#666666' /> : null
                                                        ))[0]}
                                                        {isoTotal_Month_Year_KeyValuePair.map(a => (
                                                            a.NYISO != null ? <Area type="monotone" stackId="1" name='NYISO' dataKey='NYISO' stroke='#1B9E77' fill='#1B9E77' /> : null
                                                        ))[0]}
                                                        {isoTotal_Month_Year_KeyValuePair.map(a => (
                                                            a.PJM != null ? <Area type="monotone" stackId="1" name='PJM' dataKey='PJM' stroke='#389FD3' fill='#389FD3' /> : null
                                                        ))[0]}

                                                        <Brush />
                                                    </AreaChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </>
                                    }
                                </>
                                : <LinearProgress />}
                        </CardHeader>

                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card chart className={classes.cardHover}>
                        <CardBody>
                            <h4 className={classes.cardTitle}>View ISO Data by Monthly</h4>
                        </CardBody>
                        <CardHeader>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    views={["year", "month"]}
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Please Select the Date to View"
                                    format="MM/dd/yyyy"
                                    // selectedDate="12/01/2020"
                                    minDate={new Date("2020-01-01")}
                                    maxDate={new Date("2030-01-12")}
                                    value={selecteddate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            {this.state.isLoadingGraph === 1 ? <>
                                <ResponsiveContainer height={200}>
                                    <BarChart data={this.state.testData}
                                        margin={{ top: 0, right: 0, left: 0, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey='name' tick={<CustomizedAxisTick />} />

                                        <Bar barSize={20} dataKey='value' fill='#D95F02'>
                                            {
                                                this.state.testData.map((entry, index) => {
                                                    return <Cell key={`cell-${index}`} fill={entry.color} /*dataKey={entry.value}*/ />
                                                })
                                            }
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                                <DashboardKeyValuePairTable
                                    table_data={this.state.testData}
                                    condition={false}
                                /></> : <><p>Please Select the Date to See the Graph</p></>
                            }
                        </CardHeader>
                    </Card>
                </GridItem>
            </>
        )
    }
    render() {
        return (<>
            <this.VolumeStackedAreaChartContainerUI />
        </>
        )
    }
}
export default VolumeStackedAreaChartContainer;