import React from 'react';
import {
    XAxis, YAxis, Tooltip, CartesianGrid, Brush, ResponsiveContainer, LineChart, Line, Legend
} from 'recharts';
import { CustomToolTipDollar, CustomizedAxisTick,CustomYAxisDollar } from '../../utils/rechartHelper'
export default function GrossMarginByEDCLineGraph({ grossMarginEDCDetails_KeyValuePair }) {
  //  console.log(grossMarginEDCDetails_KeyValuePair)
    return (
        <>
            <ResponsiveContainer height={600}>
                <LineChart data={grossMarginEDCDetails_KeyValuePair}
                    margin={{ top: 10, right: 5, left: 25, bottom: 0 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" height={60} tick={<CustomizedAxisTick />} />
                    <YAxis tick={<CustomYAxisDollar />}/>
                    <Tooltip content={<CustomToolTipDollar/>} />
                    <Legend />
                    {grossMarginEDCDetails_KeyValuePair.map(a => (
                        a.edcName != null ? <Line type="monotone" name={a.edcName} dataKey='edcValue' stroke={a.ISOColor} fill={a.ISOColor} /> : null
                    ))[0]}
                    <Brush />
                </LineChart>
            </ResponsiveContainer>
        </>
    )
}