import React, { Component } from 'react';
import {
    VolumeStackedAreaChartContainer
} from '../../containers';
import { getMonths, isoColor } from '../../utils/helper';
import LinearProgress from '@material-ui/core/LinearProgress';
import IncomeByPortfolio from '../../elements/IncomeStatement/IncomeByPortfolio';
import IncomeByIso from '../../elements/IncomeStatement/IncomeByIso';
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TimelineIcon from '@material-ui/icons/Timeline';
// core components
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';
import { IncomeStatementQuery, FileDownloadIncomeStatement } from "../../graphQL_Query/IncomeStatementQuery"
import API, { graphqlOperation } from "@aws-amplify/api";
import { ColorRadioButton, ColorFormlabel, ColorButtonAlignRight } from '../../utils/materialUI_Reuseable_Comp'
//import { onClick_DownloadIamge_Third, onClick_DownloadIamge_Fourth } from '../../utils/downloadGraphImage'
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles)

const stylesDialog = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(stylesDialog)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export class IncomeStatement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: '0',
            selectedYearId: 0,
            months: [],
            graphQLIncomeBy_Format: null,
            getIncomeStatementData: null,
            getRarrangeIncomeStatementData_Portfolio_Gross: null,
            getRarrangeIncomeStatementData_ISO_Gross: null,
            getRarrangeIncomeStatementData_Portfolio_Unit: null,
            getRarrangeIncomeStatementData_ISO_Unit: null,
            //extract data for below
            forwardGrossMargins_total: [],
            HedgeMTM_total: [],
            volume_total: [],
            generateYearList: [],
        };
        this.changeView = this.changeView.bind(this);
        this.handleYearSelect = this.handleYearSelect.bind(this);
    }
    componentDidMount() {
        API.graphql(graphqlOperation(IncomeStatementQuery)).then(({ data: { getIncomeStatement } }) => {
            if (getIncomeStatement !== null) {
                const months = getMonths();
                this.setState({ getIncomeStatementData: getIncomeStatement, months })
                //console.log(getIncomeStatement)
                this.GraphQLReformatData_Yearly_Monthly(getIncomeStatement);
            }
        });
    }
    GraphQLReformatData_Yearly_Monthly = (getIncomeStatement_Data) => {
        var YearList = [], generateYearList = []
        getIncomeStatement_Data.forEach(row => {
            let newValues = {};
            row.values.forEach(val => newValues[val.year] = val.values.reduce((a, vy) => {
                a.yearly += vy.value;
                YearList.push(val.year)
                a.monthly[vy.month] = vy.value;
                return a;
            }, { yearly: 0, monthly: {} }))
            row.values = newValues;
        });
        //console.log(YearList)
        const uniqueYears = YearList.filter((val, id, array) => array.indexOf(val) === id);
        //console.log(uniqueYears)
        uniqueYears.sort((a, b) => a - b)
        // console.log(uniqueYears)
        for (let i = 0; i < uniqueYears.length; i++) {
            generateYearList.push({
                year: uniqueYears[i],
                id: i
            })
        }
        //console.log(generateYearList)
        this.setState({
            graphQLIncomeBy_Format: getIncomeStatement_Data,
            generateYearList: generateYearList
        })
        this.GraphQLReformatData_UnderContract_Table_Chart(getIncomeStatement_Data)
        this.IncomeStatementSortData(getIncomeStatement_Data)
    };

    // SortData the data inorder the income statement need to be dispalyed 
    IncomeStatementSortData = (incomeStatementData) => {
        //console.log(incomeStatementData)
        // for gross margin view
        var NetMargin = [], Contract_Revenue = [], TotalCost = [], CommodityCost = [], HedgeMTM = [], ContractMargin = [],
            FeeRevenue = [], Commission = [], Volume = []
        incomeStatementData.map(a => {
            // console.log(a)
            if (a.type === "Net Margin") {
                NetMargin.push(a)
            }
            if (a.type === "Contract Revenue" || a.type === "Total Revenue") {
                Contract_Revenue.push(a)
            }
            if (a.type === "Total Cost") {
                TotalCost.push(a)
            }
            if (a.type === "Commodity Costs") {
                CommodityCost.push(a)
            }
            if (a.type === "Hedge MTM") {
                HedgeMTM.push(a)
            }
            if (a.type === "Contract Margin") {
                ContractMargin.push(a)
            }
            if (a.type === "Fees" || a.type === "Fee Revenue") {
                FeeRevenue.push(a)
            }
            if (a.type === "Commission" || a.type === "Commission Obligation") {
                Commission.push(a)
            }
            if (a.type === "Volume") {
                Volume.push(a)
            }
            return null
        })
        // combine array for porfolio and income by iso table
        var reArrangeData_Portfolio_Gross = [...NetMargin, ...Contract_Revenue, ...TotalCost, ...CommodityCost,
        ...HedgeMTM, ...ContractMargin, ...FeeRevenue, ...Commission, ...Volume]
        var reArrangeData_ISO_Gross = [...Contract_Revenue, ...TotalCost, ...CommodityCost, ...HedgeMTM,
        ...ContractMargin, ...FeeRevenue, ...Commission, ...NetMargin, ...Volume]

        // console.log(reArrangeData_Portfolio_Gross)
        // for unit margin view
        var reArrangeData_Portfolio_Unit = [], Portfolio_Unit = {}, Volume_List = []

        reArrangeData_Portfolio_Gross.map(a => {
            if (a.type === 'Volume') {
                Volume_List.push(a)
            }
            return null
        })
        // console.log(Volume_List)
        if (reArrangeData_ISO_Gross[0] !== undefined) {
            var ISO_values_year = {}, ISO_values_month = {}            //contract revenue and unit margin
            Volume_List.map(d => {
                reArrangeData_ISO_Gross.map(a => {
                    if (a.iso === d.iso) {
                        if (a.type === "Net Margin" && a.type !== "Volume") {
                            Portfolio_Unit['iso'] = a.iso
                            Portfolio_Unit['type'] = 'Unit Margin'
                            Portfolio_Unit['subType'] = 'Unit Margin'
                            Portfolio_Unit['component'] = 'Unit Margin'
                            Portfolio_Unit['values'] = this.state.generateYearList.map(b => {
                                // console.log(a)
                                ISO_values_year[b.year] = {
                                    yearly: a.values[b.year] !== undefined && d.values[b.year] !== undefined ? a.values[b.year]['yearly'] / d.values[b.year]['yearly'] : 0,
                                    monthly: this.state.months.map(c => {
                                        if (a.values[b.year] !== undefined && d.values[b.year] !== undefined) {
                                            if (a.values[b.year]['monthly'][c.name] !== undefined) {
                                                ISO_values_month[c.name] = a.values[b.year]['monthly'][c.name] / d.values[b.year]['monthly'][c.name]
                                            }
                                        }
                                        return ISO_values_month
                                    })[0]
                                }
                                return ISO_values_year
                            })[0]
                            ISO_values_month = {}
                            ISO_values_year = {}
                            reArrangeData_Portfolio_Unit.push(Portfolio_Unit)
                            Portfolio_Unit = {}
                        } else if (a.type !== "Volume") {
                            Portfolio_Unit['iso'] = a.iso
                            Portfolio_Unit['type'] = a.type
                            Portfolio_Unit['subType'] = a.subType
                            Portfolio_Unit['component'] = a.component
                            Portfolio_Unit['values'] = this.state.generateYearList.map(b => {
                                ISO_values_year[b.year] = {
                                    yearly: a.values[b.year] !== undefined && d.values[b.year] !== undefined ? a.values[b.year]['yearly'] / d.values[b.year]['yearly'] : 0,
                                    monthly: this.state.months.map(c => {
                                        if (a.values[b.year] !== undefined && d.values[b.year] !== undefined) {
                                            if (a.values[b.year]['monthly'][c.name] !== undefined) {
                                                ISO_values_month[c.name] = a.values[b.year]['monthly'][c.name] / d.values[b.year]['monthly'][c.name]
                                            }
                                        }
                                        return ISO_values_month
                                    })[0]
                                }
                                return ISO_values_year
                            })[0]
                            ISO_values_month = {}
                            ISO_values_year = {}
                            reArrangeData_Portfolio_Unit.push(Portfolio_Unit)
                            Portfolio_Unit = {}
                        } else if (a.type === "Volume") {
                            reArrangeData_Portfolio_Unit.push(a)
                        }
                    }
                    return null
                })
                return null
            })
        }

        var NetMargin_Unit = [], Contract_Revenue_Unit = [], TotalCost_Unit = [], CommodityCost_Unit = [], HedgeMTM_Unit = [],
            ContractMargin_Unit = [], FeeRevenue_Unit = [], Commission_Unit = [], Volume_Unit = []
        reArrangeData_Portfolio_Unit.map(a => {
            // console.log(a)
            if (a.type === "Net Margin" || a.type === "Unit Margin") {
                NetMargin_Unit.push(a)
            }
            if (a.type === "Contract Revenue" || a.type === "Total Revenue") {
                Contract_Revenue_Unit.push(a)
            }
            if (a.type === "Total Cost") {
                TotalCost_Unit.push(a)
            }
            if (a.type === "Commodity Costs") {
                CommodityCost_Unit.push(a)
            }
            if (a.type === "Hedge MTM") {
                HedgeMTM_Unit.push(a)
            }
            if (a.type === "Contract Margin") {
                ContractMargin_Unit.push(a)
            }
            if (a.type === "Fees" || a.type === "Fee Revenue") {
                FeeRevenue_Unit.push(a)
            }
            if (a.type === "Commission" || a.type === "Commission Obligation") {
                Commission_Unit.push(a)
            }
            if (a.type === "Volume") {
                Volume_Unit.push(a)
            }
            return null
        })

        // combine array for porfolio and income by iso table
        var reArrangeData_Portfolio_UnitMargin = [...NetMargin_Unit, ...Contract_Revenue_Unit, ...TotalCost_Unit, ...CommodityCost_Unit,
        ...HedgeMTM_Unit, ...ContractMargin_Unit, ...FeeRevenue_Unit, ...Commission_Unit, ...Volume_Unit]
        var reArrangeData_ISO_UnitMargin = [...Contract_Revenue_Unit, ...TotalCost_Unit, ...CommodityCost_Unit, ...HedgeMTM_Unit,
        ...ContractMargin_Unit, ...FeeRevenue_Unit, ...Commission_Unit, ...NetMargin_Unit, ...Volume_Unit]

        // console.log(reArrangeData_Portfolio_UnitMargin)
        this.setState({
            getRarrangeIncomeStatementData_Portfolio_Gross: reArrangeData_Portfolio_Gross,
            getRarrangeIncomeStatementData_ISO_Gross: reArrangeData_ISO_Gross,
            getRarrangeIncomeStatementData_Portfolio_Unit: reArrangeData_Portfolio_UnitMargin,
            getRarrangeIncomeStatementData_ISO_Unit: reArrangeData_ISO_UnitMargin
        })

    }
    // this is the main function which return the data for gross margin, volume, and average of it from graphql query data
    GraphQLReformatData_UnderContract_Table_Chart = (graphQLDashboard_Format_Data) => {
        var forwardGrossMargins_total = [],
            HedgeMTM_total = [],
            volume_total = []
        //console.log(this.state.graphQLDashboard_Format)
        //check if the data is not null else do not process
        //console.log(graphQLDashboard_Format_Data)
        if (graphQLDashboard_Format_Data !== null) {
            graphQLDashboard_Format_Data.map(a => {
                // gross margin
                if (a.type === "Gross Margin") {
                    ///console.log(a.values)
                    var sum_margin = 0
                    this.state.generateYearList.map(b => {
                        //console.log(a.values['2024'])
                        if (a.values[b.year] !== undefined) {
                            // console.log(b.year)
                            // console.log(a.values[b.year].yearly)
                            sum_margin += a.values[b.year].yearly
                        }
                        return null
                    })
                    // console.log(sum_margin)
                    forwardGrossMargins_total.push({
                        name: a.iso,
                        value: sum_margin,
                        color: isoColor(a.iso),
                        type: 'dollar'
                    })

                }
                //hedge MTM value
                if (a.type === "Hedge MTM") {
                    var sum_hedge = 0
                    this.state.generateYearList.map(b => {
                        if (a.values[b.year] !== undefined) {
                            sum_hedge += a.values[b.year].yearly
                        }
                        return null
                    })
                    HedgeMTM_total.push({
                        name: a.iso,
                        value: sum_hedge,
                        color: isoColor(a.iso),
                        type: 'dollar'
                    })
                }
                //volume
                if (a.type === "Volume") {
                    //console.log(a)
                    var sum_volume = 0
                    this.state.generateYearList.map(b => {
                        if (a.values[b.year] !== undefined) {
                            sum_volume += a.values[b.year].yearly
                        }
                        return null
                    })
                    volume_total.push({
                        name: a.iso,
                        value: sum_volume, // total volume of each iso
                        allValues: a.values, // volume of each iso by year and month
                        color: isoColor(a.iso),
                        type: 'volume'
                    })
                }
                return null
            })
        }
        //console.log(forwardGrossMargins_total)
        // console.log(HedgeMTM_total)
        // console.log(volume_total)
        this.setState({
            forwardGrossMargins_total,
            HedgeMTM_total,
            volume_total,
            isLoaded: true,
        })
        //this.PortfolioMetricsContainerUI()
    };
    changeView(e) {
        const v = e.currentTarget.getAttribute('value');
        this.setState({
            view: v
        });
    }

    itemRenderer(item, { handleClick }) {
        return (
            <MenuItem
                key={item.id}
                text={item.year}
                shouldDismissPopover={true}
                onClick={handleClick}
            />
        )
    }
    handleYearSelect(y) {
        this.setState({
            selectedYearId: y.id
        })
    };

    IncomeStatementUI = () => {
        const classes = useStyles();
        const [YearMonthView, setYearMonthView] = React.useState('0');
       // const [GrossUnitView, setGrossUnitView] = React.useState('gross');
        const [selectedYear, setSelectedYear] = React.useState('2020'); // hooks to handle iso selected state
        var DropDownYearList = [];
        const inputLabel = React.useRef(null);
        const [labelWidth, setLabelWidth] = React.useState(0);
        const [promiseDataCSVLink, setPromiseDataCSVLink] = React.useState() // unpromise the csv link and save into state to download the data
        const [open, setOpen] = React.useState(false); // set the state for dialog open
        const [CSVLinkNotAvailable, setCSVLinkNotAvailable] = React.useState(false)

        React.useEffect(() => {
            setLabelWidth(100);
        }, []);
        const handleYearMonthView = event => {
            this.setState({ view: event.target.value })
            //console.log(event.target.value)
            setYearMonthView(event.target.value);
        };
      /*  const handleGrossUnitView = event => {
            setGrossUnitView(event.target.value);
        };*/
        const handleSelectedYear = event => {
            this.setState({ selectedYear: event.target.value })
            this.state.generateYearList.map(a => {
                if (a.year === event.target.value) {
                    this.setState({ selectedYearId: a.id })
                }
                return null
            })
            setSelectedYear(event.target.value);
        };
        // handle dialog open / close for CSV Link download
        const handleClose_CSVFileDialog = () => {
            setOpen(false);
            setCSVLinkNotAvailable(false)
            setPromiseDataCSVLink(undefined)
        };
        // handle download CSV File download 
        const handleCSVDownload_OnClick = () => {
            setOpen(true);
            var filter_CSVFile = API.graphql(graphqlOperation(FileDownloadIncomeStatement,
                { filetype: "income_statement" }))
                .then(({ data: { getFileDownload } }) => {
                    // console.log(getFileDownload)
                    return getFileDownload
                });
            filter_CSVFile.then(a => {
                //  console.log(a)
                setPromiseDataCSVLink(a)
            }).catch(() => {
                setCSVLinkNotAvailable(true)
                // console.log(a)
            })
            //console.log(filter_CSVFile)
            // console.log(promiseDataCSVLink)
        }
        DropDownYearList = this.state.generateYearList.map(a => a.year)

        return (
            <>
                <GridContainer>
                    <GridItem xs={12}>
                        <Card elevation={3}>
                            <CardHeader color="success" icon>
                                <CardIcon color="success">
                                    <TimelineIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>Income Statement</h4>
                            </CardHeader>
                            <CardBody>
                                {/*   <FormControl component="fieldset" className={classes.formControl}>
                                    <ColorFormlabel component="legend">Select Gross/Unit Margin View</ColorFormlabel>
                                    <RadioGroup aria-label="gender" name="gender1" value={GrossUnitView} onChange={handleGrossUnitView} style={{ display: 'inline', flexDirection: 'row' }}>
                                        <FormControlLabel value='gross' control={<ColorRadioButton />} label="Gross Margin" />
                                        <FormControlLabel value='unit' control={<ColorRadioButton />} label="Unit Margin" />
                                &nbsp;&nbsp;&nbsp;
                                    </RadioGroup>
                                </FormControl>
        */}
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <ColorFormlabel component="legend">Select Yearly/Monthly View</ColorFormlabel>
                                    <RadioGroup aria-label="gender" name="gender1" value={YearMonthView} onChange={handleYearMonthView} style={{ display: 'inline', flexDirection: 'row' }}>
                                        <FormControlLabel value='0' control={<ColorRadioButton />} label="Annual" />
                                        <FormControlLabel value='1' control={<ColorRadioButton />} label="Monthly" />
                                        <ColorButtonAlignRight variant="outlined" color="primary" onClick={handleCSVDownload_OnClick}>
                                            Generate CSV File      </ColorButtonAlignRight>
                                        <Dialog onClose={handleClose_CSVFileDialog} aria-labelledby="customized-dialog-title" open={open}>
                                            {promiseDataCSVLink === undefined ?
                                                <>
                                                    <DialogTitle id="customized-dialog-title" onClose={handleClose_CSVFileDialog}>
                                                        File Download      </DialogTitle>
                                                    <DialogContent dividers>
                                                        {CSVLinkNotAvailable === false ?
                                                            <>
                                                                <Alert severity="info">File generating, please wait!</Alert><br></br>
                                                                <LinearProgress />
                                                            </>
                                                            :
                                                            <>
                                                                <Alert severity="error">Problem with file download</Alert>
                                                            </>
                                                        }
                                                    </DialogContent>
                                                    <DialogActions></DialogActions>
                                                </>
                                                :
                                                <>
                                                    <DialogTitle id="customized-dialog-title" onClose={handleClose_CSVFileDialog}>
                                                        File Download        </DialogTitle>
                                                    <DialogContent dividers>
                                                        <Typography gutterBottom>
                                                            <Alert severity="success">Please click button to download!</Alert><br></br>
                                                        </Typography>
                                                        <ColorButtonAlignRight variant="contained" color="primary" href={promiseDataCSVLink} target="_blank">
                                                            Download CSV</ColorButtonAlignRight>
                                                    </DialogContent>
                                                    <DialogActions></DialogActions>
                                                </>
                                            }
                                        </Dialog>
                                &nbsp;&nbsp;&nbsp;
                                    </RadioGroup>
                                </FormControl>
                                <FormControl variant="outlined"
                                    className={classes.formControl}
                                    style={this.state.view === "0" ?
                                        { visibility: 'hidden', cursor: 'pointer' } : null} >
                                    <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">Select Year</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        labelWidth={labelWidth}
                                        value={selectedYear}
                                        onChange={handleSelectedYear}                                    >
                                        {DropDownYearList.map(a => (
                                            <MenuItem key={a} value={a}>
                                                {a}
                                            </MenuItem >
                                        ))}
                                    </Select>
                                </FormControl>
                                <h5 className={classes.cardTitle}>Portfolio
                                &nbsp; {/*<ColorButtonAlignRight variant="contained" color="primary" className={classes.margin} onClick={onClick_DownloadIamge_Third}>Download Graph</ColorButtonAlignRight>*/}</h5>
                                <CardBody>
                                    {this.state.getRarrangeIncomeStatementData_Portfolio_Gross !== null ?
                                        <div id="my-node3">
                                            <IncomeByPortfolio
                                                view={this.state.view}
                                                year={this.state.selectedYearId}
                                                yearList={this.state.generateYearList}
                                                monthList={this.state.months}
                                                IncomeByPortfoliaData={ this.state.getRarrangeIncomeStatementData_Portfolio_Gross}/*{
                                                    GrossUnitView === 'gross' ? this.state.getRarrangeIncomeStatementData_Portfolio_Gross :
                                                        this.state.getRarrangeIncomeStatementData_Portfolio_Unit
                                                }*/
                                               // GrossUnitView={GrossUnitView}
                                            />
                                        </div> : <LinearProgress />
                                    }
                                </CardBody>

                                <h5 className={classes.cardTitle}>Income by ISO &nbsp; {/*<ColorButtonAlignRight variant="contained" color="primary" className={classes.margin} onClick={onClick_DownloadIamge_Fourth}>Download Graph</ColorButtonAlignRight>*/}</h5>
                                <CardBody>
                                    {this.state.getRarrangeIncomeStatementData_ISO_Gross !== null ?
                                        <div id="my-node4">
                                            <IncomeByIso
                                                view={this.state.view}
                                                year={this.state.selectedYearId}
                                                yearList={this.state.generateYearList}
                                                monthList={this.state.months}
                                                IncomeByISOData= {this.state.getRarrangeIncomeStatementData_ISO_Gross} /*{GrossUnitView === 'gross' ? this.state.getRarrangeIncomeStatementData_ISO_Gross :
                                                    this.state.getRarrangeIncomeStatementData_ISO_Unit
                                                }*/
                                             //   GrossUnitView={GrossUnitView}
                                            />
                                        </div> : <LinearProgress />}
                                </CardBody>
                            </CardBody >
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color="success" icon>
                                <CardIcon color="success">
                                    <TimelineIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                    MWh Under Contract
                                            </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer justify="space-between">
                                    <VolumeStackedAreaChartContainer
                                        volume_total={this.state.volume_total}
                                        yearList={this.state.generateYearList}
                                    />
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        )
    }
    render() {
        return (<>
            <this.IncomeStatementUI />
        </>)
    }
}
export default IncomeStatement;