import React from 'react';
import { aggregateValue_forGraph, aggregateValue } from 'elements/ReactTableReUseAbleComponent/MaterialUITable';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
export const CustomizedAxisTick = props => {
    const { x, y, payload } = props
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-10)" style={{ fontSize: 14 }}>{payload.value}</text>
        </g>
    );
};
export const CustomizedAxisTickVertical = props => {
    const { x, y, payload } = props
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={8} textAnchor="end" fill="#666" transform="rotate(0)" style={{ fontSize: 14 }}>{payload.value}</text>
        </g>
    );
};
export const CustomizedAxisTickAnalysisDashboard = props => {
    const { x, y, payload } = props
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-10)" style={{ fontSize: 14 }}>{payload.value}</text>
        </g>
    );
};

export const CustomToolTipMWh = (props) => {
    const { active, payload } = props;
    var data1 = 0;
    // console.log(payload)

    if (!active || !payload) {
        return null;
    }
    else {

        data1 = payload[0].payload;
        //console.log(data1)
        return (
            <>
                <Card>
                    <CardHeader>
                        <strong>{data1.date}</strong>
                        {payload.map((item) => (
                            <strong style={{ color: item.color }} key={item.value}>
                                {data1.year !== null ? data1.year : null}
                                <br />{item.name}: {aggregateValue(item.value, false)}
                            </strong>
                        ))}
                    </CardHeader>
                </Card>

            </>
        );
    }
};
export const CustomToolTipMWhAnaDashboard = (props) => {
    const { active, payload } = props;
    var data1 = 0;
    // console.log(payload)

    if (!active || !payload) {
        return null;
    }
    else {

        data1 = payload[0].payload;
        //console.log(data1)
        return (
            <>
                <Card>
                    <CardHeader>
                        <strong>{data1.date}</strong>
                        {payload.map((item) => (
                            <strong style={{ color: data1.color }} key={item.value}>
                                {data1.name !== null ? <>EDC: {data1.name}</> : null}
                                <br />{item.name}: {aggregateValue(item.value, false)}
                            </strong>
                        ))}
                    </CardHeader>
                </Card>

            </>
        );
    }
};
export const CustomToolTipDollarMWhAnaDashboard = (props) => {
    const { active, payload } = props;
    var data1 = 0;
    // console.log(payload)

    if (!active || !payload) {
        return null;
    }
    else {

        data1 = payload[0].payload;
        //console.log(data1)
        return (
            <>
                <Card>
                    <CardHeader>
                        <strong>{data1.date}</strong>
                        {payload.map((item) => (
                            <strong style={{ color: data1.color }} key={item.value}>
                                {data1.name !== null ? <>EDC: {data1.name}</> : null}
                                <br />{item.name}: {item.value.toFixed(2) + ' $/MWh'}
                            </strong>
                        ))}
                    </CardHeader>
                </Card>

            </>
        );
    }
};
export const CustomToolTipDollar = (props) => {
    const { active, payload } = props;
    var data1 = 0;
    // console.log(payload)

    if (!active || !payload) {
        return null;
    }
    else {
        data1 = payload[0].payload;
        //console.log(data1)
        return (
            <>
                <Card>
                    <CardHeader>
                        <strong>{data1.date}</strong>
                        {payload.map((item) => (
                            <strong style={{ color: item.color }} key={item.value}>
                                {data1.year !== null ? data1.year : null}
                                <br />{item.name}: {aggregateValue(item.value, true)}
                            </strong>
                        ))}
                    </CardHeader>
                </Card>

            </>
        );
    }
};
export const CustomToolTipDollarAnaDashboard = (props) => {
    const { active, payload } = props;
    var data1 = 0;
    // console.log(payload)

    if (!active || !payload) {
        return null;
    }
    else {
        data1 = payload[0].payload;
        //console.log(data1)
        return (
            <>
                <Card>
                    <CardHeader>
                        <strong>{data1.date}</strong>
                        {payload.map((item) => (
                            <strong style={{ color: data1.color }} key={item.value}>
                                {data1.year !== null ? data1.year : null}
                                {data1.name !== null ? <>EDC: {data1.name}</> : null}
                                <br />{item.name}: {aggregateValue(item.value, true)}
                            </strong>
                        ))}
                    </CardHeader>
                </Card>

            </>
        );
    }
};
export const CustomToolTipDollarRiskPNL = (props) => {
    const { active, payload } = props;
    var data1 = 0;
    // console.log(payload)

    if (!active || !payload) {
        return null;
    }
    else {
        data1 = payload[0].payload;
        //console.log(data1)
        return (
            <>
                <Card>
                    <CardHeader>
                        <strong>{data1.date}</strong>
                        {payload.map((item) => (
                            <strong style={{ color: data1.color }} key={item.value}>
                                {data1.name !== null ? <>Name: {data1.name}</> : null}
                                <br />Value: {aggregateValue(item.value, true)}
                                <br /> Count: {data1.count}
                            </strong>
                        ))}
                    </CardHeader>
                </Card>
            </>
        );
    }
};
export const CustomToolTipIncomeStatement = (props) => {
    const { active, payload } = props;
    var data1 = 0;
    // console.log(payload)

    if (!active || !payload) {
        return null;
    }
    else {
        data1 = payload[0].payload;
        //  console.log(data1)
        return (
            <>
                <Card>
                    <CardHeader>
                        <strong>Year: {data1.year}</strong>
                        {payload.map((item) => (
                            <strong style={{ color: item.color }} key={item.value}>
                                <br />{item.groupByVal !== "Volume" ?
                                    <>Value: {aggregateValue(item.value, true)}</> :
                                    <>Volume: {aggregateValue(item.value, false)}</>
                                }
                            </strong>
                        ))}
                    </CardHeader>
                </Card>

            </>
        );
    }
};

export const CustomYAxisDollar = (props) => {
    const { x, y, payload } = props
    var data1 = payload.value
    var valToReturn = aggregateValue_forGraph(data1, true)
    // console.log(valToReturn)
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={5} textAnchor="end" fill="#666" transform="rotate(0)" style={{ fontSize: 14 }}>
                {valToReturn}</text>
        </g>
    )
}
export const CustomXAxisDollarVertical = (props) => {
    const { x, y, payload } = props
    var data1 = payload.value
    var valToReturn = aggregateValue_forGraph(data1, true)
    // console.log(valToReturn)
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={20} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(0)" style={{ fontSize: 14 }}>
                {valToReturn}</text>
        </g>
    )
}
export const CustomYAxisMWh = (props) => {
    const { x, y, payload } = props
    var data1 = payload.value
    var valToReturn = aggregateValue_forGraph(data1, false)
    //console.log(valToReturn)
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={5} textAnchor="end" fill="#666" transform="rotate(0)" style={{ fontSize: 14 }}>
                {valToReturn}</text>
        </g>
    )
}
export const CustomXAxisDollarMWhVertical = (props) => {
    const { x, y, payload } = props
    //console.log(payload)
    var data1 = payload.value
    var valToReturn = (data1 * 1000) + ' $/MWh'//aggregateValue_forGraph(data1, false)
    //console.log(valToReturn)
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={20} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(0)" style={{ fontSize: 14 }}>
                {valToReturn}</text>
        </g>
    )
}
export const CustomYAxisMWhAnalysisFiveEDCDash = (props) => {
    const { x, y, payload } = props
    var data1 = payload.value
    var valToReturn = aggregateValue_forGraph(data1, false)
    //console.log(valToReturn)
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={5} textAnchor="end" fill="#666" transform="rotate(0)" style={{ fontSize: 14 }}>
                {valToReturn}</text>
        </g>
    )
}
export const CustomYAxisMWhDollarAnalysisFiveEDCDash = (props) => {
    const { x, y, payload } = props
    var data1 = payload.value
    var valToReturn = data1
    //console.log(valToReturn)
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={5} textAnchor="end" fill="#666" transform="rotate(0)" style={{ fontSize: 14 }}>
                {valToReturn} $/MWh</text>
        </g>
    )
}
export const CustomToolTipDashboardVolume = (props) => {
    const { active, payload } = props;
    var data;
    //console.log(payload)
    if (!active || !payload) {
        return null;
    }
    else {
        data = payload[0].payload;
        //   console.log(data)
        return (
            <>
                <Card>
                    <CardHeader>
                        <strong style={{ color: data.color }} >
                            {data.iso === undefined ? data.date : data.iso}
                            <br /> {aggregateValue(data.value, false)}
                        </strong>
                    </CardHeader>
                </Card>
            </>
        );
    }
};
export const CustomToolTipDashboardDollar = (props) => {
    const { active, payload } = props;
    var data;
    //console.log(payload)
    if (!active || !payload) {
        return null;
    }
    else {
        data = payload[0].payload;
        //  console.log(data)
        return (
            <>
                <Card>
                    <CardHeader>
                        <strong style={{ color: data.color }}>
                            {data.iso === undefined ? data.date : data.iso}
                            <br /> {aggregateValue(data.value, true)}
                        </strong>
                    </CardHeader>
                </Card>
            </>
        );
    }
};
export const CustomToolTipMwhAnalysisFiveContract = (props) => {
    const { active, payload } = props;
    var data = 0;
    const subStyle = {
        color: 'red'
    };
    //console.log(payload)
    if (!active || !payload) {
        return null;
    }
    else {
        data = payload[0].payload;
        //console.log(payload)
        return (
            <>
                <Card>
                    <CardHeader>
                        <strong style={{ color: data.color }}>
                            Company: {data.CompanyName}
                            <br /> Value: {parseFloat(data.Value) < 0 ? <span style={subStyle}>({data.Value.replace("-", "")} MWh)</span> : <span>{data.Value} MWh</span>}
                        </strong>
                    </CardHeader>
                </Card>
            </>
        );
    }
};
export const CustomToolTipDollarAnalysisFiveContract = (props) => {
    const { active, payload } = props;
    var data = 0;
    //console.log(payload)
    if (!active || !payload) {
        return null;
    }
    else {
        data = payload[0].payload;
        //console.log(payload)
        return (
            <>
                <Card>
                    <CardHeader>
                        <strong style={{ color: data.color }}>
                            Company: {data.CompanyName}
                            <br /> Value: {aggregateValue(data.Value, true)}
                        </strong>
                    </CardHeader>
                </Card>
            </>
        );
    }
};
export const Custom_Brush = (props) => {
    const { x, y, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                textAnchor="end"
                fill="#666"
                transform="rotate(-45)">
                {payload.value}
            </text>
        </g>
    );
}