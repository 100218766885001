import React from 'react';
import {
    XAxis, YAxis, Tooltip, CartesianGrid, Brush, ResponsiveContainer, LineChart, Line
} from 'recharts';
import { calculatePercentage } from '../../utils/helper'
import { monthNumber_to_Name } from '../../utils/helper'

// @material-ui/core components
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import { aggregateValue } from 'elements/ReactTableReUseAbleComponent/MaterialUITable';
import { CustomizedAxisTick, CustomYAxisDollar } from '../../utils/rechartHelper'
import TopBottomAllAccountsDetails from './TopBottomAllAccountsDetails'
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

}));
export default function GorssMarginByAccountCustomerDetails(grossMarginChartData) {
    var grossMarginChartData_CustomerDetails = grossMarginChartData.grossMarginChartData
    var isLoadingGraph = 0;
    var CustomerDetails_KeyValuePair = [], isoList = [], isoSelectedCompanyList = [];
    var top20Accounts = null, bottom20Accounts = null, allAccountsDetails = null;
    const [selectISOName, setSelectISOName] = React.useState([]); // hooks to handle iso selected state
    const [selectCompanyName, setSelectCompanyName] = React.useState([]); // hooks to handle company selected by iso

    // handle selected iso name
    const handleChangeISO = event => {
        setSelectISOName(event.target.value);
    };
    // handle selected company name after selection iso
    const handleChangeCompanyName = event => {
        setSelectCompanyName(event.target.value)
    };
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    // customize tooltip to show data on hover on axis
    const CustomToolTip = (props) => {
        const { active, payload } = props;
        var data = 0;
        // console.log(payload)
        if (!active || !payload) {
            return null;
        }
        else {
            data = payload[0].payload;
            // console.log(data)
            return (
                <>
                    <Card>
                        <CardHeader>
                            <strong style={{ color: data.isoColor }}>
                                ISO: {data.iso == null ? data.name : data.iso}
                                <br /> Total ISO Value: {aggregateValue(data.TotalValue, true)}
                                <br /> Company: {data.isoChild == null ? data.isoChild : data.isoChild}
                                <br /> Company Value: {aggregateValue(data.isoChildValue, true)}
                                <br /> Total Accounts: {data.isoChildChildTotalAccount}
                                <br /> Percentage($): {parseFloat(calculatePercentage(data.isoChildValue, data.TotalValue)).toFixed(2)}%
                                <br /> Accout No: {data.Account_No}
                                <br /> Accout Value: {aggregateValue(data.Account_Value, true)}
                                <br /> Contract Type: {data.Contract_Type}
                                <br /> Sign Date: {data.Contract_Sign_Date}
                                <br /> Start Date: {data.startDate}
                                <br /> End Date: {data.endDate}

                            </strong>
                        </CardHeader>
                    </Card>
                </>
            );
        }
    };
    // create a key pair value on the basis of selection of iso and its company 
    if (grossMarginChartData_CustomerDetails != null) {
        //console.log(grossMarginChartData_CustomerDetails)
        grossMarginChartData_CustomerDetails.children.map(a => {
            a.children.map(b => {
                b.children.map(c => {
                    if (selectCompanyName === b.name) {
                        CustomerDetails_KeyValuePair.push({
                            iso: a.name,
                            TotalValue: a.value,
                            isoColor: a.color,
                            isoChild: b.name,
                            isoChildValue: b.value,
                            isoChildChildTotalAccount: b.children.length,
                            Account_No: c.name,
                            Account_Value: c.value,
                            Contract_Type: c.type,
                            Contract_Sign_Date: c.contractSignedDate.getMonth() + " " + monthNumber_to_Name(c.contractSignedDate.getMonth()) + ", " + c.contractSignedDate.getFullYear(),
                            startDate: c.startDate.getMonth() + " " + monthNumber_to_Name(c.startDate.getMonth()) + ", " + c.startDate.getFullYear(),
                            endDate: c.endDate.getMonth() + " " + monthNumber_to_Name(c.endDate.getMonth()) + ", " + c.endDate.getFullYear()
                        })
                        isLoadingGraph = 1
                        top20Accounts = b.topAccounts
                        bottom20Accounts = b.bottomAccounts
                        allAccountsDetails = b.children
                    }
                    return null
                })
                return null
            })
            return null
        })
        //TopBottomAllAccountsDetails({ top20Accounts, bottom20Accounts, allAccountsDetails, isLoadingGraph })
        isoList = (grossMarginChartData_CustomerDetails.children.map(a => a.name))
        grossMarginChartData_CustomerDetails.children.map(a => {
            if (selectISOName === a.name) {
                isoSelectedCompanyList = a.children.map(b => b.name)
            }
            return null
        })
        //console.log(CustomerDetails_KeyValuePair)
        //console.log(isoList)
        // console.log(selectISOName)
        // console.log(isoSelectedCompanyList)
        // console.log(selectCompanyName)
    }
    const classes = useStyles();
    /*
    1st option will show the iso from the render list and 
    2nd option will show the company name under selected iso
    after selecting both option the graph will be grawn to show the data with account deatils for 
    each company under each iso.
    */
    return (
        <>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader>
                            <h4 className={classes.cardTitle}>
                                Customer Level Details of Specific Account's in ISO</h4>
                        </CardHeader>
                        <CardBody>

                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">ISO</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={selectISOName}
                                    onChange={handleChangeISO}
                                    labelWidth={labelWidth}
                                >
                                    {isoList.map(a => (
                                        <MenuItem key={a} value={a}>
                                            {a}
                                        </MenuItem >
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">EDC</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={selectCompanyName}
                                    onChange={handleChangeCompanyName}
                                    labelWidth={labelWidth}
                                >
                                    {isoSelectedCompanyList.map(a => (
                                        <MenuItem key={a} value={a}>
                                            {a}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField className={classes.formControl} label="Enter Accout No" variant="outlined" />
                            <Button variant="contained" className={classes.formControl} color="primary">
                                Search Account </Button>
                            {isLoadingGraph === 1 ?
                                <>  <ResponsiveContainer height={500} width="100%">
                                    <LineChart data={CustomerDetails_KeyValuePair}
                                        margin={{ top: 10, right: 5, left: 25, bottom: 0 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="Account_No" height={60} tick={<CustomizedAxisTick />} />
                                        <YAxis tick={<CustomYAxisDollar />} />
                                        <Tooltip content={<CustomToolTip />} />
                                        <Line type="monotone" dataKey='Account_Value' stroke='#07298A' />
                                        <Brush />
                                    </LineChart>
                                </ResponsiveContainer>
                                </> : <><p>Please Select the Options to See the Graph</p></>
                            }
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            {isLoadingGraph === 1 ? <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader>
                            <h4 className={classes.cardTitle}>
                                Top\Bottom 20 or All Account Details</h4>
                        </CardHeader>
                        <CardBody>
                            <TopBottomAllAccountsDetails
                                top20Accounts={top20Accounts}
                                bottom20Accounts={bottom20Accounts}
                                allAccountsDetails={allAccountsDetails}
                                isLoadingGraph={isLoadingGraph}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer> : null}
        </>
    )
}