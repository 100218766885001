import API, { graphqlOperation } from "@aws-amplify/api";
import { getListOfAvailableClient } from '../graphQL_Query/UserSelection'

export function DefaultUser() {
    API.graphql(graphqlOperation(getListOfAvailableClient))
        .then(({ data: getAvailableClients }) => {
            var defaultUser = getAvailableClients.getAvailableClients
            var clientAbbreviation = [], clientDisplayName = []
            //console.log(defaultUser)
            defaultUser.map(a => {
                clientAbbreviation.push(a.client)
                clientDisplayName.push(a.displayName)
                return null
            })
            //console.log(clientAbbreviation)
            //console.log(clientDisplayName)
            window.localStorage.setItem(`defaultUser`, defaultUser[0].client);
            window.localStorage.setItem(`defaultUserName`, defaultUser[0].displayName);
            window.localStorage.setItem(`clientAbbreviationLists`, clientAbbreviation);
            window.localStorage.setItem(`clientDisplayNameList`, clientDisplayName);
            DefaultDate(defaultUser[0].client)
        });
}
export function DefaultDate(defaultSelectedClient) {
    //console.log(defaultSelectedClient);
    const getListOfDates = `query getAvailableReports {
        getAvailableReports(client:"${defaultSelectedClient}")
    }`
    //window.localStorage.setItem(`dfadsfs`, getListOfDates)
    API.graphql(graphqlOperation(getListOfDates))
        .then(({ data: getAvailableReports }) => {
            var defaultReportDates = getAvailableReports.getAvailableReports
            //console.log(defaultReportDates)
            // console.log(this.state.isoName)
            window.localStorage.setItem(`defaultDate`, defaultReportDates[0]);
            window.localStorage.setItem(`userDates`, defaultReportDates);
            // window.localStorage.setItem(`reportDateList`, defaultReportDates);
        });
}
