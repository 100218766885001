import React from 'react';
import {
    XAxis, YAxis, Tooltip, CartesianGrid, Brush, ResponsiveContainer, LineChart, Line, Legend
} from 'recharts';
import { CustomToolTipMWh, CustomizedAxisTick, CustomYAxisMWh } from '../../utils/rechartHelper'

export default function VolumeByEDCLineGraph({ volumeEDCDetails_KeyValuePair }) {

    return (
        <>
            <ResponsiveContainer height={600}>
                <LineChart data={volumeEDCDetails_KeyValuePair}
                    margin={{ top: 10, right: 5, left: 60, bottom: 0 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" height={60} tick={<CustomizedAxisTick />} />
                    <YAxis tick={<CustomYAxisMWh />} />
                    <Tooltip content={<CustomToolTipMWh />} />
                    <Legend />
                    {volumeEDCDetails_KeyValuePair.map(a => (
                        a.edcName != null ? <Line type="monotone" name={a.edcName} dataKey='edcValue' stroke={a.ISOColor} fill={a.ISOColor} /> : null
                    ))[0]}
                    <Brush />
                </LineChart>
            </ResponsiveContainer>
        </>
    )
}