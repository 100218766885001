import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';

export const onClick_DownloadIamge = () => {
    domtoimage.toBlob(document.getElementById('my-node'))
        .then(function (blob) {
            saveAs(blob, 'myNode.jpg');
        });
}

export const onClick_DownloadIamge_Second = () => {
        domtoimage.toBlob(document.getElementById('my-node2'))
        .then(function (blob) {
            saveAs(blob, 'myNode.jpg');
        });
}

export const onClick_DownloadIamge_Third = () => {
    domtoimage.toBlob(document.getElementById('my-node3'))
    .then(function (blob) {
        saveAs(blob, 'myNode.jpg');
    });
}
export const onClick_DownloadIamge_Fourth = () => {
    domtoimage.toBlob(document.getElementById('my-node4'))
    .then(function (blob) {
        saveAs(blob, 'myNode.jpg');
    });
}
export const onClick_DownloadIamge_GroupingTable = () => {
    domtoimage.toBlob(document.getElementById('my-node-group'))
    .then(function (blob) {
        saveAs(blob, 'myNode.jpg');
    });
}