import React from "react";
import { FormGroup, FormControl, InputGroup } from "react-bootstrap";
//import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
//import FormControl from '@material-ui/core/FormControl';
import './LoginStyle.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import logoImage from '../../Logos/logo-2x.png'
import { Auth } from "aws-amplify";
import LoaderButton from '../../components/LoadingButton/LoaderButton';
//import Button from '@material-ui/core/Button';
import { ColorButtonSubmit, ColorButtonBack } from '../../utils/materialUI_Reuseable_Comp'
import { DefaultUser } from 'ClientReportDateSelection/DefaultUser.js'

var errorLoginDisplay;

export class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isLoadingUsername: false,
      isLoadingNewPassowrd: false,
      // user login 
      email: '',
      password: '',
      // first time user login to reconfigure passowrd
      completeNewUserPassword: '',
      showCompleteNewUserPassword: false,
      //below variable to reset the current user passoword using 'forget password'
      AuthCodeCondition: true,
      AuthCodeToPassword: true,
      NewPasswordCondition: true,
      //enter username/email to reset password
      enterUserNameToRestPassword: '',
      //condition and message for code send/error
      errorSuccessCodeSendMessage: '',
      errorSuccessCodeSendConditionTrue: false,
      errorSuccessCodeSendConditionFalse: false,
      verificationCode: '',
      //condition and message for set newpassword 
      errorSuccessPasswordSendMessage: '',
      errorSuccessPasswordConditionTrue: false,
      errorSuccessPasswordConditionFalse: false,
      newPassoword: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAuthenticationCodeSend = this.handleAuthenticationCodeSend.bind(this);
    this.handleSetNewPassoword = this.handleSetNewPassoword.bind(this);
  }

  getLoginUserName() {
    return this.props.authData
  }
  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  validateFormRestPass() {
    return this.state.completeNewUserPassword.length > 0
  }

  handleChange = event => {
    //console.log(event.target.value)
    this.setState({ [event.target.id]: event.target.value });
  };

  // handle login for current user 
  // if the user is first time user then it will require them to reset their password
  handleSubmit = async event => {
    try {
      event.preventDefault();
      await Auth.signIn(this.state.email, this.state.password) // authenticated user login data
        .then((user) => {
          // console.log(user)
          // console.log("Confirm Sign In");

          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            this.setState({ showCompleteNewUserPassword: true });
            // console.log(this.state.completeNewUserPassword)
            if (this.state.completeNewUserPassword !== null) {
              Auth.completeNewPassword(user, this.state.completeNewUserPassword,
                {
                  name: user.challengeParam.userAttributes.email
                })
                .then((a) => {
                  //  console.log(a)
                  DefaultUser()
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }).catch(error => {
                  //   console.log(error)
                });
            }
          }
          if (this.state.showCompleteNewUserPassword === false) {
            this.setState({ errors: {}, isLoading: true });
            DefaultUser()
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        })
        .catch((err) => {
          //alert(err.message); // unable to vertify secrect message display
          errorLoginDisplay = err.message;
          // console.log(err.message);
          // console.log("Not Able to Login In");
          this.setState({ isLoading: false });
          console.log(err)
        });
    } catch (e) {
      alert(e.message); // unable to vertify secrect message display
      //console.log(e.message);
      // console.log("Not Able to Login In");
      this.setState({ isLoading: false });
    }
  };

  // process to handle send verification code to user to reset password
  handleAuthenticationCodeSend = async () => {
    console.log(this.state.enterUserNameToRestPassword)
    await Auth.forgotPassword(this.state.enterUserNameToRestPassword)
      .then(data => {
        console.log(data);
        this.setState({
          isLoadingUsername: true,
          AuthCodeCondition: false,
          AuthCodeToPassword: true,
          errorSuccessCodeSendMessage: "Code Send Successful",
          errorSuccessCodeSendConditionTrue: true
        })
      }).catch((err) => {
        console.log("ERROR: ", err.message);
        this.setState({
          isLoadingUsername: false,
          AuthCodeCondition: true,
          AuthCodeToPassword: true,
          errorSuccessCodeSendMessage: err.message,
          errorSuccessCodeSendConditionFalse: true
        })

      })
  }
  // process to change the user password 
  handleSetNewPassoword = async () => {
    //  console.log(this.state.completeNewUserPassword)
    await Auth.forgotPasswordSubmit(this.state.enterUserNameToRestPassword, this.state.verificationCode, this.state.newPassoword)
      .then(data => {
        //    console.log(data);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        this.setState({
          isLoadingNewPassowrd: true,
          NewPasswordCondition: false,
          errorSuccessPasswordConditionTrue: true,
          errorSuccessPasswordSendMessage: "New Password Set Successful",
        })
      }).catch((err) => {
        //  console.log("ERROR: ", err.message);
        this.setState({
          isLoadingNewPassowrd: false,
          NewPasswordCondition: true,
          errorSuccessPasswordConditionFalse: true,
          errorSuccessPasswordSendMessage: err.message,

        })

      })
  }

  //UI to for login, reset password
  LoginUI = () => {
    const [openLogin, setOpenLogin] = React.useState(true);
    const [backAuthCode, setBackAuthCode] = React.useState(true);
    const [backSetPassword, setBackSetPassword] = React.useState(true);

    const handleClickForgetPassword = () => {
      setOpenLogin(false);
      setBackAuthCode(true);
      setBackSetPassword(true);
    };
    const handleBackAuthcodeSend = () => {
      setOpenLogin(true);
      setBackAuthCode(true);
      setBackSetPassword(false);
    };
    const handleBackSetNewPasswordConfirmed = () => {
      setOpenLogin(true);
      setBackAuthCode(false);
      setBackSetPassword(false);
    };

    console.log(this.props);
    return (
      <div className="LoginBackgroundColor">
        {(this.props.authState !== 'signedIn' || this.props.authData === null) && 
          <div className="TextCopyRight">
            <div className="Login">
              <img src={logoImage} className="center" alt="Teleios Logo" height="250px" width="350px"></img>
              <>
                {openLogin === true ?
                  <>
                    <form onSubmit={this.handleSubmit}>
                      <FormLabel><u>Sign-In</u></FormLabel>
                      <FormGroup controlId="email">
                        <FormLabel >Email</FormLabel>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            autoFocus
                            type="text"
                            placeholder="enter email address..."
                            value={this.state.email}
                            onChange={this.handleChange}
                            disabled={this.state.showCompleteNewUserPassword}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup controlId="password">
                        <FormLabel>Password</FormLabel>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroupPrepend">&#9919;</InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            value={this.state.password}
                            onChange={this.handleChange}
                            type="password"
                            placeholder="enter password..."
                            disabled={this.state.showCompleteNewUserPassword}
                          />
                        </InputGroup>
                      </FormGroup>

                      {this.state.showCompleteNewUserPassword === true ?
                        //process to set new password for first time user
                        <>
                          <FormGroup controlId="password">
                            <FormLabel>Setup New Password</FormLabel>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroupPrepend">&#9919;</InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                //  value={this.state.completeNewUserPassword}
                                onChange={e => this.setState({ completeNewUserPassword: e.target.value })}
                                type="password"
                                placeholder="enter password..."
                              />
                            </InputGroup>
                          </FormGroup>
                        </> : null}
                      <FormLabel className="LoginErrorDisplay">{errorLoginDisplay}</FormLabel>
                      <LoaderButton
                        block
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Login"
                        loadingText="Logging in…"
                      />
                      <Link component="button" variant="body2" onClick={handleClickForgetPassword}>Forgot Password?</Link>
                    </form>
                  </> // enter user name to receive verification code
                  : backAuthCode === true && this.state.AuthCodeCondition === true ?
                    <>
                      <form onSubmit={this.handleAuthenticationCodeSend}>
                        <FormGroup controlId="password">
                          <FormLabel> Email/Username</FormLabel>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              value={this.state.enterUserNameToRestPassword}
                              onChange={e => this.setState({ enterUserNameToRestPassword: e.target.value })}
                              type="text"
                              placeholder="Enter Email/Username..."
                              disabled={this.state.showCompleteNewUserPassword}
                            />
                          </InputGroup>
                        </FormGroup>
                        {this.state.errorSuccessCodeSendConditionTrue === true ? <Alert severity="success">{this.state.errorSuccessCodeSendMessage}</Alert> : null}
                        {this.state.errorSuccessCodeSendConditionFalse === true ? <Alert severity="error">{this.state.errorSuccessCodeSendMessage}</Alert> : null}
                        <ColorButtonBack onClick={handleBackAuthcodeSend}>Back</ColorButtonBack>&nbsp;&nbsp;&nbsp;
                        <ColorButtonSubmit onClick={this.handleAuthenticationCodeSend}>Submit</ColorButtonSubmit>

                      </form>
                    </>// enter verfication code and set new password 
                    : backSetPassword === true && this.state.NewPasswordCondition === true && this.state.AuthCodeToPassword === true ?
                      <>
                        <form onSubmit={this.handleSetNewPassoword}>
                          <FormGroup controlId="password">
                            <FormLabel>Verification Code</FormLabel>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroupPrepend">*</InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                value={this.state.verificationCode}
                                onChange={e => this.setState({ verificationCode: e.target.value })}
                                type="verificatoncode"
                                placeholder="Verification Code..."
                              //disabled={this.state.showCompleteNewUserPassword}
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup controlId="password">
                            <FormLabel>New Password</FormLabel>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroupPrepend">&#9919;</InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                value={this.state.newPassoword}
                                onChange={e => this.setState({ newPassoword: e.target.value })}
                                type="password"
                                placeholder="Enter New Password..."
                              />
                            </InputGroup>
                          </FormGroup>
                          {this.state.errorSuccessPasswordConditionTrue === true ? <Alert severity="success">{this.state.errorSuccessPasswordSendMessage}</Alert> : null}
                          {this.state.errorSuccessPasswordConditionFalse === true ? <Alert severity="error">{this.state.errorSuccessPasswordSendMessage}</Alert> : null}

                          <ColorButtonBack onClick={handleBackSetNewPasswordConfirmed}>Back</ColorButtonBack>&nbsp;&nbsp;&nbsp;
                          <ColorButtonSubmit onClick={this.handleSetNewPassoword}>Submit</ColorButtonSubmit>
                        </form>
                      </>
                      : null
                }
              </>
            </div>
            <br></br>
            <FormLabel> {'\u00A9'}2020 TELEIOS COMMODITIES ALL RIGHTS RESERVED </FormLabel>
          </div>}
      </div>
    );
  }
  render() {
    return (
      <>
        <this.LoginUI />
      </>
    )
  }
}
export default Login;