import React from "react";
import { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import _ from 'lodash';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { LinearProgress } from '@material-ui/core';
import { isoColor } from '../../utils/helper';
import {
    ColorRadioButton, ColorFormlabel
} from '../../utils/materialUI_Reuseable_Comp'
import GrossMarginAnalysisEDC from '../../containers/PortfolioMetricAnalysis/GrossMarginAnalysisEDC.js';
import UnitMarginAnalysisEDC from '../../containers/PortfolioMetricAnalysis/UnitMarginAnalysisEDC.js';
import VolumeAnalysisEDC from '../../containers/PortfolioMetricAnalysis/VolumeAnalysisEDC.js';
import API, { graphqlOperation } from "@aws-amplify/api";
import { ISOEDC_GrossMarginVolume } from "../../graphQL_Query/AnalysisOfFiveEDCQuery"
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);
class AnalysisFiveContract extends Component {
    constructor() {
        super();
        this.state = {
            //ISO EDC Data new graphql query 
            getGraphQL_AnalysisFiveContract_ISO_EDC: null,
            getGraphQL_AnalysisFiveContract_Format_ISO_EDC: null,
            //EDC Data for table
            getGraphQL_AnalysisFiveContract_GrossMargin_KeyPair: null,
            getGraphQL_AnalysisFiveContract_Volume_KeyPair: null,
            getGraphQL_AnalysisFiveContract_UnitMargin_KeyPair: null,
            //top-bottom edc
            GrossMargin_Top_Five: null,
            GrossMargin_Bottom_Five: null,
            Volume_Top_Five: null,
            Volume_Bottom_Five: null,
            UnitMargin_Top_Five: null,
            UnitMargin_Bottom_Five: null
        }
    }
    componentDidMount() {
        // fetch the data and pass it to function for formating
        API.graphql(graphqlOperation(ISOEDC_GrossMarginVolume)).then(({ data: { getMonthlyData } }) => {
            //console.log(getMonthlyData)
            if (getMonthlyData !== null) {
                this.setState({ getGraphQL_AnalysisFiveContract_ISO_EDC: getMonthlyData })
                this.GraphQLReformatData_Yearly_Monthly_ISO_EDC(getMonthlyData);
            }
        });
    }
  
    // reformat the data for EDC graph by monthly yearly
    GraphQLReformatData_Yearly_Monthly_ISO_EDC = (getGraphQL_AnalysisFiveContract_ISO_EDC) => {
        var getGraphQL_AnalysisFiveContract_ISO_EDC_KeyPair = []
        getGraphQL_AnalysisFiveContract_ISO_EDC.map(a => {
            a.edcs.map(b => {
                b.iso = a.iso
                b.edcName = b.name
                b.type = a.type
                var calculatedYearly = b.values.map(c => {
                    c.yearly = _.sum(c.values.map(d => d.value))
                    return c
                })
                b.values = calculatedYearly
                return null
            })
            return null
        })
        getGraphQL_AnalysisFiveContract_ISO_EDC.map(a => {
            a.edcs.map(b => {
                //  console.log(b)
                getGraphQL_AnalysisFiveContract_ISO_EDC_KeyPair.push({
                    iso: a.iso,
                    type: a.type,
                    edcName: b.name,
                    color: isoColor(a.iso),
                    values: b.values,
                    total: _.sum(b.values.map(c => c.yearly))
                })
                return null
            })
            return null
        })

       // console.log(getGraphQL_AnalysisFiveContract_ISO_EDC)
       // console.log(getGraphQL_AnalysisFiveContract_ISO_EDC_KeyPair)
        this.GraphQLReformatData_EDC_Table(getGraphQL_AnalysisFiveContract_ISO_EDC_KeyPair) // send data to format data for table to display edc
        this.setState({
            getGraphQL_AnalysisFiveContract_Format_ISO_EDC: getGraphQL_AnalysisFiveContract_ISO_EDC_KeyPair,
        })
    };
    // reformat data by gross margin, volume, unit margin so it can be pass to further class
    GraphQLReformatData_EDC_Table = (getGraphQL_ForwardMargin_EDC_Table) => {
        var getGraphQL_GrossMargin_KeyPair = [], getGraphQL_Volume_KeyPair = [], getGraphQL_UnitMargin_KeyPair = []
        getGraphQL_ForwardMargin_EDC_Table.map(a => {
            if (a.type !== "volume") {
                getGraphQL_GrossMargin_KeyPair.push({
                    iso: a.iso,
                    type: a.type,
                    name: a.edcName,
                    color: isoColor(a.iso),
                    value: a.total
                })
            } else {
                getGraphQL_Volume_KeyPair.push({
                    iso: a.iso,
                    type: a.type,
                    name: a.edcName,
                    color: isoColor(a.iso),
                    value: a.total
                })
            }
            return null
        })
        for (let i = 0; i < getGraphQL_GrossMargin_KeyPair.length; i++) {
            if (getGraphQL_GrossMargin_KeyPair[i].name === getGraphQL_Volume_KeyPair[i].name) {
                getGraphQL_UnitMargin_KeyPair.push({
                    iso: getGraphQL_GrossMargin_KeyPair[i].iso,
                    type: 'unit',
                    name: getGraphQL_GrossMargin_KeyPair[i].name,
                    color: isoColor(getGraphQL_GrossMargin_KeyPair[i].iso),
                    value: (getGraphQL_GrossMargin_KeyPair[i].value / getGraphQL_Volume_KeyPair[i].value) * 1000
                })
            }
        }
        //console.log(getGraphQL_GrossMargin_KeyPair)
        //console.log(getGraphQL_Volume_KeyPair)
        // console.log(getGraphQL_UnitMargin_KeyPair)
        // sort the data from highest to lowest
        getGraphQL_GrossMargin_KeyPair.sort((a, b) => b.value - a.value)
        getGraphQL_Volume_KeyPair.sort((a, b) => b.value - a.value)
        getGraphQL_UnitMargin_KeyPair.sort((a, b) => b.value - a.value)
        //console.log(getGraphQL_GrossMargin_KeyPair)
        //console.log(getGraphQL_Volume_KeyPair)
        // console.log(getGraphQL_UnitMargin_KeyPair)
        this.setState({
            getGraphQL_AnalysisFiveContract_GrossMargin_KeyPair: getGraphQL_GrossMargin_KeyPair,
            getGraphQL_AnalysisFiveContract_Volume_KeyPair: getGraphQL_Volume_KeyPair,
            getGraphQL_AnalysisFiveContract_UnitMargin_KeyPair: getGraphQL_UnitMargin_KeyPair,
        })
    };

    AnalysisFiveContractUI = () => {
        const [graphViewState, setGraphViewState] = React.useState('0');
        const classes = useStyles();
        const handleViewGraphChnage = event => {
            setGraphViewState(event.target.value);
        };
        // console.log(this.state.grossMarginTotals)
        return (
            <>
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color="success" icon>
                                <CardIcon color="success">
                                    <RemoveRedEyeIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                    Margin Under Contract
                                        </h4>
                            </CardHeader>
                            <CardBody>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <ColorFormlabel component="legend">Please Select the View</ColorFormlabel>
                                    <RadioGroup aria-label="gender"
                                        name="gender1"
                                        value={graphViewState}
                                        onChange={handleViewGraphChnage}
                                        style={{ display: 'inline', flexDirection: 'row' }}>
                                        <FormControlLabel value="0" control={<ColorRadioButton />} label="Gross Margin" />
                                        <FormControlLabel value="1" control={<ColorRadioButton color="primary" />} label="Volume" />
                                        <FormControlLabel value="2" control={<ColorRadioButton color="primary" />} label="Unit Margin" />
                                    </RadioGroup>
                                </FormControl>
                                {graphViewState === '0' ?
                                    <GrossMarginAnalysisEDC
                                        GrossMarginKeyPair={this.state.getGraphQL_AnalysisFiveContract_GrossMargin_KeyPair}
                                        valueSign={graphViewState}
                                    />
                                    : graphViewState === '1' ?
                                        <VolumeAnalysisEDC
                                            VolumeKeyPair={this.state.getGraphQL_AnalysisFiveContract_Volume_KeyPair}
                                            valueSign={graphViewState}
                                        />
                                        : graphViewState === '2' ?
                                            <UnitMarginAnalysisEDC
                                                UnitMarginKeyPair={this.state.getGraphQL_AnalysisFiveContract_UnitMargin_KeyPair}
                                                valueSign={graphViewState}
                                            />
                                            : <LinearProgress />
                                }
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>

        )
    }
    render() {
        return (
            <>
                <this.AnalysisFiveContractUI />
            </>
        )
    }
}
export default AnalysisFiveContract;
