import teleiosLogoColor from './logo-2x.png';
import teleiosLogoBw from './teleiosLogo.png';
import getLoginUserName from './getLoginUserName'

export const getCompanyName = (authData) => {
    getLoginUserName(authData)
    const group = authData ? authData.signInUserSession.idToken.payload['cognito:groups'][0] : 'demo';
    //console.log(group)
    let LoginName;
    const formatGroup = group.toLowerCase();
    switch (formatGroup) {
        case 'demo':
            LoginName = 'Teleios';
            break;
        case 'just':
            LoginName = 'Just Energy';
            break;
        case 'crius':
            LoginName = 'Crius Energy';
            break;
        case 'aep':
            LoginName = 'AEP';
            break;
        case 'apg':
            LoginName = 'APG';
            break;
        case 'liberty':
            LoginName = 'Liberty';
            break;
        case 'pulse':
            LoginName = 'Pulse Power';
            break;
        case 'entrust':
            LoginName = 'Entrust';
            break;
        case 'shell':
            LoginName = 'Shell';
            break;
        case 'tara':
            LoginName = 'Shell';
            break;
        case 'atlantic':
            LoginName = 'Atlantic Energy';
            break;
        case 'southbay':
            LoginName = 'South Bay Energy';
            break;
        default:
            LoginName = 'Teleios';
            break;
    }
    return LoginName;
}

export {
    teleiosLogoColor,
    teleiosLogoBw
}

