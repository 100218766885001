
import React, { Component } from 'react';
import FilterControlOptionsList from './FilterControlOptionsList'
import FilterControlDateRange from './FilterControlDateRange'
import LinearProgress from '@material-ui/core/LinearProgress';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);

class FilterControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false
        }
        this.props = props;
        this.defaultOptionsState = {};
        this.defaultDateRangeState = {};
        this.filterOptionsChange = this.filterOptionsChange.bind(this);
        this.filterDatesChange = this.filterDatesChange.bind(this);
    }
    dateChange(d) {
        // console.log('DateRangeFilter' + JSON.stringify(d));
    }

    filterOptionsChange(newOptions) {
        // console.log('Updating Options')
        // console.log(JSON.stringify(Array.from(newOptions.entries())));
        this.setState(prevState => ({ filter: { ...prevState.filter, options: newOptions } }),
            () => {
                // console.log('Filtering:' + this.state.filter)
                this.props.onChange(this.state.filter)
            })

    }
    filterDatesChange(newDates) {
        //console.log(newDates)
        // console.log('Updating Filters')
        this.setState(prevState => ({ filter: { ...prevState.filter, dateRanges: newDates } }),
            () => this.props.onChange(this.state.filter))

    }
    FilterControlUI = () => {
        const classes = useStyles();
        if (!this.props.loaded) {
            return <LinearProgress />
        } else {
            var defaultOptionsState = this.defaultOptionsState;
            var defaultDateRangeState = this.defaultDateRangeState;
            var optionsState = { defaultOptionsState, ...this.props.optionsState };
            var dateRangesState = { defaultDateRangeState, ...this.props.dateRangesState }
            return (
                <>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card chart className={classes.cardHover}>
                                <CardBody>
                                    <h4 className={classes.cardTitle}>Contract Date Range</h4>
                                </CardBody>
                                <CardHeader>
                                    {this.props.dateRangeLabel &&
                                        <FilterControlDateRange
                                            headerLabel={this.props.dateRangeLabel}
                                            dateRanges={this.props.dateRanges}
                                            onChange={this.filterDatesChange}
                                            state={dateRangesState} />
                                    }
                                </CardHeader>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card chart className={classes.cardHover}>
                                <CardBody>
                                    <h4 className={classes.cardTitle}>Option Selection</h4>
                                </CardBody>
                                <CardHeader>
                                    {this.props.optionsHeaderLabel &&
                                        <FilterControlOptionsList
                                            headerLabel={this.props.optionsHeaderLabel}
                                            options={this.props.options}
                                            onChange={this.filterOptionsChange}
                                            state={optionsState} />
                                    }
                                </CardHeader>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </>
            )
        }
    }
    render() {
        return (<this.FilterControlUI />)
    }
}
export default FilterControl;