import React from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { aggregateValue } from 'elements/ReactTableReUseAbleComponent/MaterialUITable';
const useStyles = makeStyles(styles);

export default function DashboardKeyValuePairTable({ table_data = null, condition, table_data_average, color }) {
    const classes = useStyles();
    const StyledTableCell = withStyles(theme => ({
        head: {
            backgroundColor: 'rgb(21, 96, 153)',
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const StyledTableRow = withStyles(theme => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.background.default,
            },
        },
    }))(TableRow);

    const subStyle = {
        color: 'red'
    };
    //   console.log(table_data)
    return (
        <div className="table sticky">
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>ISO</StyledTableCell>
                            <StyledTableCell align="right">Value</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {table_data != null ? table_data.map(row => (
                            <StyledTableRow key={row.name === undefined ? row.iso : row.name}>
                                <StyledTableCell component="th" scope="row" background={row.color}>
                                    <i style={{ color: color ? table_data.isoColor : row.color }} className="fa fa-circle icon"></i>&nbsp;
                                    {row.name === undefined ? row.iso : row.name}
                                </StyledTableCell>
                                <StyledTableCell align="right" >
                                    {aggregateValue(parseFloat(row.value), condition)}
                                </StyledTableCell>
                            </StyledTableRow>
                        )) : table_data_average != null ?
                                table_data_average.map(row => (
                                    <StyledTableRow key={row.name}>
                                        <StyledTableCell component="th" scope="row" background={row.color}>
                                            <i style={{ color: color ? table_data.isoColor : row.color }} className="fa fa-circle icon"></i>&nbsp;
                                            {row.name}
                                        </StyledTableCell>
                                        <StyledTableCell align="right" >
                                            {parseFloat(row.value) < 0 ? <span style={subStyle}>({row.value.toFixed(2)} $/MWh)</span> : <span>{row.value.toFixed(2)} $/MWh</span>}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )) : null}
                    </TableBody>
                    <TableBody>
                        {table_data !== null ?
                            <>
                                {table_data[0].total !== undefined ?
                                    <StyledTableRow key={table_data[0].total}>
                                        <StyledTableCell component="th" scope="row" >&nbsp;<b>Total</b></StyledTableCell>
                                        <StyledTableCell align="right" >
                                            <b>{aggregateValue(parseFloat(table_data[0].total), condition)}</b>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    : null}
                            </>
                            : table_data_average != null ?
                                <>
                                    {table_data_average[0].total !== undefined ?
                                        <StyledTableRow key={table_data_average[0].total}>
                                            <StyledTableCell component="th" scope="row">&nbsp;<b>Total</b></StyledTableCell>
                                            <StyledTableCell align="right" >
                                                <b> {parseFloat(table_data_average[0].total) < 0 ? <span style={subStyle}>({table_data_average[0].total.toFixed(2)} $/MWh)</span> : <span>{table_data_average[0].total.toFixed(2)} $/MWh</span>}</b>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        : null}
                                </>
                                : null}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}