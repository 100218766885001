import React, { Component } from 'react'
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useRowSelect,
} from 'react-table'
import { useSticky } from 'react-table-sticky';
import styled from 'styled-components';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import matchSorter from 'match-sorter'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TimelineIcon from '@material-ui/icons/Timeline';
import CloseIcon from '@material-ui/icons/Close';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';

//import TableSortLabel from '@material-ui/core/TableSortLabel'
import moment from 'moment';
import { aggregateValue } from '../../elements/ReactTableReUseAbleComponent/MaterialUITable'
import { formatter, getMonths, capitalize } from '../../utils/helper';
import {
  CustomToolTipIncomeStatement,
  CustomizedAxisTick, CustomYAxisMWh, CustomYAxisDollar,
  CustomToolTipMWh, CustomToolTipDollar
} from '../../utils/rechartHelper'
import {
  Area, XAxis, YAxis, Tooltip, CartesianGrid, Legend,
  ComposedChart, Bar, Line, ResponsiveContainer
} from 'recharts';
import { ColorButton, ColorButtonAlignRight } from '../../utils/materialUI_Reuseable_Comp'
import { onClick_DownloadIamge_GroupingTable } from '../../utils/downloadGraphImage'

// remove special character 
export const removeSpecialChars = (str) => {
  //console.log(str)
  //return null
  return str.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '');
}
// style for table
const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
    boxShadow: '1px 1px 2px 1px #ccc',
    // display: 'inline-block',
    //flexShrink: 0,
  },
});

// Define a default UI for filtering
export function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length
  //console.log(filterValue)
  return (
    <div
      style={{
        display: 'inline',
        width: '130px'
      }}
    >
      <TextField
        value={filterValue || ''}

        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        label={`${count} Records`}
        //variant="outlined"
        style={{
          padding: "5px 5px 5px 5px",
          width: '110px',
          //marginRight: '0.5rem',
          backgroundColor: '#ffffff',
        }}
      />
    </div>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  const classes = useStyles();
  // Render a multi-select box
  return (
    <FormControl className={classes.formControl}>
      <NativeSelect
        value={filterValue}
        //displayempty className={classes.selectEmpty}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
        style={{ backgroundColor: '#ffffff', width: "105px" }}
      >
        <option value="">All</option >
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option >
        ))}
      </NativeSelect>
    </FormControl>

  )
}

export function SingleDateColumnFilter({
  column: { filterValue, setFilter },
}) {
  return (
    <>
      <TextField
        value={filterValue || ''}
        type="date"
        onChange={e => {
          const val = e.target.value
          setFilter(val)
        }}
        // placeholder={`S-(${min})`}
        InputLabelProps={{
          shrink: true,
        }}
        style={{
          backgroundColor: '#ffffff'
        }}
      />
    </>
  )
}
export function RangeDateColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  var [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let dateList = []
    preFilteredRows.forEach(row => {
      dateList.push(row.values[id]) // create a list of date
    })
    // find min/max date from the list
    min = dateList.sort((a, b) => new moment(a).format('YYYYMMDD') - new moment(b).format('YYYYMMDD'))[0]
    max = dateList.sort((a, b) => new moment(a).format('YYYYMMDD') - new moment(b).format('YYYYMMDD'))[dateList.length - 1]
    //console.log(dateList)
    //console.log(min)
    //console.log(max)
    return [min, max]
  }, [id, preFilteredRows])
  //console.log(id)
  //console.log(preFilteredRows)

  //console.log(filterValue)
  // Render a multi-select box
  return (
    <>
      <TextField
        value={filterValue[0] || ''}
        type="date"
        onChange={e => {
          const val = e.target.value
          // console.log(val)
          setFilter((old = []) => [val ? val : min/*undefined*/, old[1]])
          //setFilter(val)
        }}
        // placeholder={`S-(${min})`}
        style={{
          width: '100px',
          //marginRight: '0.5rem',
          backgroundColor: '#ffffff'
        }}
      />
      <TextField
        value={filterValue[1] || ''}
        type="date"
        onChange={e => {
          const val = e.target.value
          //console.log(val)
          //setFilter(val)
          setFilter((old = []) => [old[0], val ? max /*undefined*/ : max])
        }}
        // placeholder={`E-(${max})`}
        style={{
          width: '100px',
          //marginLeft: '0.5rem',
          backgroundColor: '#ffffff'
        }}
      />

    </>
  )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two

export function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id = 'Value' },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])
  //console.log(preFilteredRows[0].original.GrossMargin)
  //console.log(max)
  // console.log(filterValue)
  return (
    <>
      <div
        style={{
          // display: 'inline-block',
          width: '100%'
        }}
      >
        <TextField
          value={filterValue[0] || ''}
          type="number"
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
          }}
          placeholder={`Min (${min})`}
          style={{
            width: '100px',
            //marginRight: '0.5rem',
            backgroundColor: '#ffffff'
          }}
        />
      </div>
      <div style={{
        //display: 'flex',
        width: '100%'
      }}>
        <TextField
          value={filterValue[1] || ''}
          type="number"
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
          }}
          placeholder={`Max (${max})`}
          style={{
            width: '100px',
            //marginLeft: '0.5rem',
            backgroundColor: '#ffffff'
          }}
        />
      </div>
    </>
  )
}
export function SortingColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = ['Asc', 'Des']

  const classes = useStyles();
  // Render a multi-select box
  return (
    <FormControl className={classes.formControl}>
      <NativeSelect
        value={filterValue !== null ? filterValue : 'Asc'}
        //displayempty className={classes.selectEmpty}
        onChange={e => {
          if (e.target.value === "Asc") {
            preFilteredRows.sort((a, b) => a.values[id] - b.values[id])
            // console.log(preFilteredRows)
          } else if (e.target.value === "Des") {
            preFilteredRows.sort((a, b) => b.values[id] - a.values[id])
            // console.log(preFilteredRows)
          }
          //setFilter(e.target.value || undefined)
        }}
        style={{ backgroundColor: '#ffffff', width: "105px" }}
      >
        <option value="">--</option >
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option >
        ))}
      </NativeSelect>
    </FormControl>
  )
}
export function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// style for sticky table
const Styles = styled.div`
.table {
  .tr {
    :last-child {
      .td {
        border-bottom: 0;
      }
    }
  }

  .th{
          
          border-bottom: 1px solid #ddd;
          border-right: 1px solid #ddd;
          color: white;
          font-size: 16px;
          background-color: #156099;
          overflow: hidden;
          text-align:center;
  }
  .td {
    
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    background-color: #fff;
    font-size: 15px;
    overflow: hidden;
    text-align:center;
  }

  &.sticky {
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

    .header {
      top: 0;
      box-shadow: 0px 3px 3px #ccc;
    }
    .body {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-last-left-td] {
      box-shadow: 2px 0px 3px #ccc;
    }

    [data-sticky-first-right-td] {
      box-shadow: -2px 0px 3px #ccc;
    }
  }
}
`;

// Be sure to pass our updateMyData and the skipReset option
class SearchDataInTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedRowData: null,
      initial: 'state'
    };
    this.SearchDataInTableUI = this.SearchDataInTableUI.bind(this)
    this.OnClickDrawGraphYearly = this.OnClickDrawGraphYearly.bind(this)
  }
  // react table only modification to orginal code is assign value to state
  SearchDataInTableUI = ({ columns, data, updateMyData, skipReset }) => {
    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter(row => {
            const rowValue = row.values[id]
            return rowValue !== undefined
              ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
              : true
          })
        },
      }),
      []
    )
    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: ' ',//DefaultColumnFilter,
        // And also our default editable cell
        //Cell: EditableCell,
        // When using the useFlexLayout:
        minWidth: 130, // minWidth is only used as a limit for resizing
        width: 130, // width is used for both the flex-basis and flex-grow
        maxWidth: 200, // maxWidth is only used as a limit for resizing
      }),
      []
    )
    const classes = useStyles();
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      headerGroups,
      prepareRow,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      toggleAllRowsSelected,

      state: { pageIndex, pageSize, },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        filterTypes,
        // updateMyData isn't part of the API, but
        // anything we put into these options will
        // automatically be available on the instance.
        // That way we can call this function from our
        // cell renderer!
        updateMyData,
        // We also need to pass this so the page doesn't change
        // when we edit the data.
        autoResetPage: !skipReset,
        autoResetSelectedRows: !skipReset,
      },
      useFilters,
      useGroupBy,
      useSortBy,
      useExpanded,
      usePagination,
      useRowSelect,
      useSticky,
    )
    // below code is use to set the data for graph as user can click the button to veiw the graph of row containing data
    // this function is used to draw graph OnClickDrawGraphYearly in incomeByPorfolio and incomeByIso file
    /* if (selectedFlatRows[0] !== undefined) {
        console.log('pass')
               console.log(selectedFlatRows[0])
               this.state.selectedRowData = selectedFlatRows[0]
     }*/
    //console.log(selectedFlatRows[0])
    // Render the UI for your table

    return (
      <>
        <Styles>
          <div {...getTableProps()} className="table sticky">
            <Paper className={classes.root}>

              <Table>
                <TableHead className="header">
                  {headerGroups.map(headerGroup => (
                    <TableRow {...headerGroup.getHeaderGroupProps()} className="tr" >
                      {headerGroup.headers.map(column => (
                        <TableCell {...column.getHeaderProps()} className="th" >
                          <>
                            {column.canGroupBy ? (
                              // If the column can be grouped, let's add a toggle
                              <span {...column.getGroupByToggleProps()}>
                                {column.isGrouped}
                              </span>
                            ) : null}
                            <span {...column.getSortByToggleProps()}>
                              {column.render('Header')}
                              {/* Add a sort direction indicator */}

                              {column.isSorted
                                ? column.isSortedDesc
                                  ? ' 🔽'
                                  : ' 🔼'
                                : ''}
                              {/*column.id !== 'selection' ? (
                                <TableSortLabel
                                  active={column.isSorted}
                                  // react-table has a unsorted state which is not treated here
                                  direction={column.isSortedDesc ? 'desc' : 'asc'}
                                />
                              ) : null*/}
                            </span>
                          </>
                          {/* Render the columns filter UI */}
                          <div>{column.canFilter ? column.render('Filter') : null}</div>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody className="body">
                  {page.map(row => {
                    prepareRow(row)
                    return (
                      <TableRow  {...row.getRowProps({
                        onMouseEnter: () => {
                          toggleAllRowsSelected(false)
                          row.toggleRowSelected()
                        },
                      })} className="tr">
                        {row.cells.map(cell => {
                          //console.log(row)
                          return (
                            <TableCell {...cell.getCellProps({
                              style: {
                                backgroundColor: row.isSelected ? '#B6FD9B' : '',
                                width: '200px'
                              },
                            })} className="td">
                              {cell.isGrouped ? (
                                // If it's a grouped cell, add an expander and row count
                                <>
                                  <span {...row.getExpandedToggleProps()}>
                                    {row.isExpanded}
                                  </span>{' '}
                                  {cell.render('Cell', { editable: false })}
                                  ({row.subRows.length})
                                </>
                              ) : cell.isAggregated ? (
                                // If the cell is aggregated, use the Aggregated
                                // renderer for cell
                                cell.render('Aggregated')
                              ) : cell.isRepeatedValue ? null : ( // For cells with repeated values, render null
                                // Otherwise, just render the regular cell
                                cell.render(a => {
                                  // console.log(a)
                                  /*{'Cell', { editable: true }*/
                                  if (a.column.Header === "Volume (MWh)") {
                                    //return aggregateValue(a.cell.value, false)
                                    return formatter(a.cell.value * 0.001, 'thousands')
                                  } else if (a.column.Header === "Gross Margin") {
                                    return aggregateValue(a.cell.value, true)
                                    //return a.cell.value
                                  } else if (a.column.Header === "Unit Margin ($/MWh)") {
                                    const subStyle = {
                                      color: 'red'
                                    };
                                    return a.cell.value < 0 ? <span style={subStyle}>({a.cell.value.replace("-", "")})</span> : <span>{a.cell.value}</span>
                                  } else {
                                    return a.cell.value
                                  }
                                })
                              )}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Paper>
          </div>
        </Styles>
        {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
        <Paper>
          <ColorButton variant="contained" color="primary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </ColorButton>{' '}
          <ColorButton variant="contained" color="primary" onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </ColorButton>{' '}
          <ColorButton variant="contained" color="primary" onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </ColorButton>{' '}
          <ColorButton variant="contained" color="primary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </ColorButton>{' '}
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span>
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <FormControl className={classes.formControl}>
            <NativeSelect
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
              //displayempty className={classes.selectEmpty}
              style={{ border: 1 }}
            >
              {[10, 20, 30, 40, 50, 75, 100, 150, 200].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          &nbsp;	&nbsp;*Please click on title to sort the data in ascending or descending order
        </Paper>
      </>
    )
    //console.log(selectedFlatRows)
  }
  // arrow function to draw graph when user click on button on table
  OnClickDrawGraphYearly = ({ yearView, selectedYear }) => {
    const [open, setOpen] = React.useState(false); // handle the state of open/close
    const [GraphSelection, setGraphSelection] = React.useState('0'); //view different type of graph
    const [GraphUnit_YAxis, setGraphUnit_YAxis] = React.useState(0); //view different type of graph

    var selectedRowData_KeyPairValue = []; // reformat data where rechart understand 
    const classes = useStyles();
    // style of popper
    const styles = theme => ({
      root: {
        margin: 0,
        padding: theme.spacing(2),
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
    });
    // style of popper
    const DialogTitle = withStyles(styles)(props => {
      const { children, classes, onClose, ...other } = props;
      return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
          <Typography variant="h6">{children}</Typography>
          {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
      );
    });
    // style of popper
    const DialogContent = withStyles(theme => ({
      root: {
        padding: theme.spacing(2),
      },
    }))(MuiDialogContent);
    // style of popper
    const DialogActions = withStyles(theme => ({
      root: {
        margin: 0,
        padding: theme.spacing(1),
      },
    }))(MuiDialogActions);
    const handleClickOpen = () => {
      if (this.state.selectedRowData !== null) {
        if (this.state.selectedRowData['original'].type === 'volume') {
          setGraphUnit_YAxis(1) // change the unit sign for y-axis for graph
        }
      }
      setOpen(true);
    };
    const handleClose = () => {
      this.setState({ selectedRowData: null });
      setOpen(false);
    };
    const handleGraphSelection = event => {
      // this.setState({ view: event.target.value })
      //this.state.view = event.target.value
      //console.log(event.target.value)
      setGraphSelection(event.target.value);
    };
    //year list to display on x-axis
    const years = [{ year: '2020', id: 0 }, { year: '2021', id: 1 }, { year: '2022', id: 2 }, { year: '2023', id: 3 }, { year: '2024', id: 4 }]
    // get list of month
    const setMonths = getMonths();
    // check it the selected row data is not null
    if (this.state.selectedRowData !== null) {
      const rowData = this.state.selectedRowData['original']; // extract original data
      //console.log(rowData);
      // console.log(yearView)
      // handle the view option by yearly/monthly
      if (yearView === true) {
        years.map(a => {
          if (rowData.values[2020 + a.id] !== undefined) {
            // console.log(rowData.values[2020 + a.id])
            // create data for year view 
            selectedRowData_KeyPairValue.push({
              year: a.year,
              name: rowData.name,
              type: rowData.type,
              value: rowData.values[2020 + a.id].yearly !== undefined ? rowData.values[2020 + a.id].yearly : 0,
            })
          }
          //return selectedRowData_KeyPairValue
          return null
        })
      } else if (this.state.selectedRowData !== null) {
        //console.log(setMonths)
        //console.log(rowData.values[selectedYear].monthly)
        setMonths.map(a => {
          //console.log(rowData.values[selectedYear].monthly[a.name])
          if (rowData.values[selectedYear] !== undefined) {
            //console.log(rowData.values[selectedYear].monthly[a.name])
            //create data for month view
            selectedRowData_KeyPairValue.push({
              year: capitalize(a.name),
              name: rowData.name,
              type: rowData.type,
              value: rowData.values[selectedYear].monthly[a.name] !== undefined ? rowData.values[selectedYear].monthly[a.name] : 0,
            })
          }
          // return selectedRowData_KeyPairValue
          return null
        })
      } else {
        selectedRowData_KeyPairValue.push({
          year: null,
          name: null,
          type: null,
          value: null,
        })
      }
    }
    //console.log(selectedRowData_KeyPairValue)
    //console.log(GraphSelection)
    return (
      <>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}
          size="small" startIcon={<TimelineIcon />} fullWidth={true}>
          Visualize
                                </Button>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Graph of <strong>{this.state.selectedRowData !== null ?
              this.state.selectedRowData['original']['name'] + " by " +
              this.state.selectedRowData['original']['type'] : null}</strong></DialogTitle>
          <DialogContent dividers>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Please Select the Graph/Chart View</FormLabel>
              <RadioGroup aria-label="gender" name="gender1" value={GraphSelection} onChange={handleGraphSelection} style={{ display: 'inline', flexDirection: 'row' }}>
                <FormControlLabel value='0' control={<Radio color="primary" />} label="Bar" />
                <FormControlLabel value='1' control={<Radio color="primary" />} label="Area" />
                <FormControlLabel value='2' control={<Radio color="primary" />} label="Line" />
                <ColorButtonAlignRight variant="contained" color="primary" className={classes.margin}
                  onClick={onClick_DownloadIamge_GroupingTable}>
                  Download Graph</ColorButtonAlignRight>
              </RadioGroup>
            </FormControl>
            {GraphSelection !== '0' ? null :
              <>
                <div id="my-node-group">
                  <ResponsiveContainer height={300} width={600}>
                    <ComposedChart data={selectedRowData_KeyPairValue}
                      margin={{ top: 10, right: 40, bottom: 10, left: 40 }}
                    >
                      <XAxis dataKey="year" tick={<CustomizedAxisTick />} />
                      <YAxis tick={GraphUnit_YAxis === 0 ? <CustomYAxisDollar /> : <CustomYAxisMWh />} />
                      <Legend />
                      <CartesianGrid stroke="#f5f5f5" />
                      <Tooltip content={GraphUnit_YAxis === 0 ? <CustomToolTipDollar /> : <CustomToolTipMWh />} />
                      <Bar dataKey="value" barSize={20} fill="#156099" />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </>
            }
            {GraphSelection !== '1' ? null :
              <>
                <div id="my-node-group">
                  <ResponsiveContainer height={300} width={600}>
                    <ComposedChart data={selectedRowData_KeyPairValue}
                      margin={{ top: 10, right: 40, bottom: 10, left: 40 }}>
                      <XAxis dataKey="year" tick={<CustomizedAxisTick />} />
                      <YAxis tick={GraphUnit_YAxis === 0 ? <CustomYAxisDollar /> : <CustomYAxisMWh />} />
                      <Legend />
                      <CartesianGrid stroke="#f5f5f5" />
                      <Tooltip content={<CustomToolTipIncomeStatement />} />
                      <Area type='monotone' dataKey='value' fill='#156099' stroke='#156099' />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </>
            }
            {GraphSelection !== '2' ? null :
              <>
                <div id="my-node-group">
                  <ResponsiveContainer height={300} width={600}>
                    <ComposedChart data={selectedRowData_KeyPairValue}
                      margin={{ top: 10, right: 40, bottom: 10, left: 40 }}>
                      <XAxis dataKey="year" tick={<CustomizedAxisTick />} />
                      <YAxis tick={GraphUnit_YAxis === 0 ? <CustomYAxisDollar /> : <CustomYAxisMWh />} />
                      <Legend />
                      <CartesianGrid stroke="#f5f5f5" />
                      <Tooltip content={<CustomToolTipIncomeStatement />} />
                      <Line type="monotone" dataKey="value" stroke="#156099" />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </>
            }
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Close
                                    </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
  render() {
    // console.log(this.props.grossMarginByAccount)
    return (<this.SearchDataInTableUI />)
  }
}
export default SearchDataInTable
// Define a custom filter filter function!
export function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

// This is a custom aggregator that
// takes in an array of values and
// returns the rounded median
export function roundedMedian(values) {
  let min = values[0] || ''
  let max = values[0] || ''

  values.forEach(value => {
    min = Math.min(min, value)
    max = Math.max(max, value)
  })

  return Math.round((min + max) / 2)
}




